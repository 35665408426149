import { createSlice } from '@reduxjs/toolkit'
import aiController from 'helpers/AIController'

export const translatorInitialState = {
  history: {
    inputValue: '',
    inputLanguage: 'auto',
    outputLanguage: 'English',
    isGuruModeEnabled: false,
    isNative: true,
    isAudio: false,
    yourStory: null,
    model: { ...aiController.getDefaultTextModel(), description: undefined },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const translatorSlice = createSlice({
  name: 'translator',
  initialState: translatorInitialState,
  reducers: {
    setTranslatorInputHistory: (state, action) => {
      state.history = action.payload
    },
    setTranslatorGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTranslatorInputHistory, setTranslatorGeneratedResultHistory } =
  translatorSlice.actions

//selectors
export const translatorInputHistory = (state) => state.translator.history
export const translatorGeneratedResultHistory = (state) =>
  state.translator.generatedTextHistory.generatedText

export default translatorSlice.reducer
