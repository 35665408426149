import { Animate } from 'components/lib'
import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ChatbotLibraryListLayout } from 'toolComponents/chat-bot/library/LibraryListLayout'
import { useTranslate } from '@tolgee/react'
import { useDebounce } from 'use-debounce'

export const ChatbotLibraryCategory = () => {
  const context = useContext(ViewContext)
  const params = useParams()
  const [items, setItems] = useState([])
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 1000)
  const { t } = useTranslate()

  const fetchItems = useCallback(async () => {
    try {
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=5&category=${params.category}&filter=${debounceFilter}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [context, params.category, debounceFilter])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const title = t(params.category)

  return (
    <Animate>
      <ChatbotLibraryListLayout items={items} title={title} filter={filter} setFilter={setFilter} />
    </Animate>
  )
}

export default ChatbotLibraryCategory
