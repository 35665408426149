import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import { useEffect } from 'react'
import Guru from 'toolComponents/guru'
import MoreOptions from 'toolComponents/more-options'
import { ButtonGroup, Input, ModelSelector, ReactSelect } from 'ui'
import WriterLengthContainer from '../WriterLengthContainer'
import { Creativity, replyTextActions } from '../writer-options-data'
import { replyTextReadyMadeTemplates } from '../writer-options-data/ready-made-templates'
import aiController from 'helpers/AIController'

export default function ReplyTextOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setTextToReply,
  setWhatToReply,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  optionsData,
  templateOptions,
  setTemplateId,
  allowStandard,
  showUpgradePlanModal,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  documentContext,
  model,
  setModel,
}) {
  const isPanelLarge = usePanelSize('reply')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(replyTextReadyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  return (
    visible && (
      <div
        className={classNames(
          'flex flex-col gap-[16px] break-words',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div className='!w-full'>
          <ContextField documentContext={documentContext} />
        </div>
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            id='template'
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-reply-template'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-template'>Template</T>
            </FieldDescription>
            <ReactSelect
              options={templateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              customOptions={readyMadeTemplatesData}
              // defaultValue={options[0]}
              setSelectedValue={handleSelectTemplate}
              value={templateId.value ? templateId : null}
              showDeleteIcon
              deleteAction={(templateId) => handleDeleteTemplate(templateId)}
              name='TEMPLATE'
            />
          </div>
          <div className='flex justify-end'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={model}
              handleModelChange={(model) => setModel({ ...model, description: undefined })}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://www.techopedia.com/claude-vs-chatgpt'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
          {/* <div
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-reply-myStory'
          >
            <FieldDescription>
              <T keyName='eleo-your-story'>Your story</T>
            </FieldDescription>
            <ReactSelect
              options={storyTemplateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // disabled={!allowStandard}
              // defaultValue={options[0]}
              setSelectedValue={setStoryId}
              value={storyId.value ? storyId : null}
              name='STORY'
              menuAlignment='right'
              action={() => navigate('/account/your-story')}
              actionLabel={t('eleo-add', 'Add')}
              menuMinWidth={200}
            />
          </div> */}
        </div>
        <div
          id='text-to-reply-to'
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='writer-reply-myText'
        >
          <FieldDescription>
            <T keyName='eleo-tool-reply-text-to-reply-to'>Text To Reply To</T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-reply-text-to-reply-placeholder',
              'Provide more details about your text'
            )}
            className='placeholder-font-regular min-h-[220px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setTextToReply}
            value={optionsData.textToReply}
            isTextArea
          />
        </div>
        <div
          id='what-to-reply'
          className='flex w-full items-center gap-[12px]'
          data-tooltip-id='writer-reply-whatToDo'
        >
          <FieldDescription>
            <T keyName='eleo-tool-reply-what-to-reply'>What To Reply?</T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-reply-what-to-reply-placeholder', 'What To Reply?')}
            className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.whatToReply}
            onChange={setWhatToReply}
          />
        </div>

        {/* Show advanced button */}
        <div className='writer-tour-options flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        <div
          className={classNames(
            'flex flex-col gap-[16px]',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
        >
          <div
            className='flex w-full items-center gap-[10px]'
            data-tooltip-id='writer-reply-actions'
          >
            <FieldDescription>
              <T keyName='eleo-tool-reply-write-a-reply-as'>Write A Reply As:</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={replyTextActions}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(replyTextActions, optionsData.state.action, null)}
                name='ACTION'
                menuPlacement='top'
                isClearable
                clearLabel={t('eleo-clear-button-label-none', 'None')}
              />
            </div>
          </div>
          <div
            className='flex w-full items-center gap-[12px]'
            data-tooltip-id='writer-reply-length'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>

          <div
            className='flex w-full  items-center gap-[10px] '
            data-tooltip-id='writer-reply-language'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={languages}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(
                  languages,
                  optionsData.state.language,
                  defaultToolOutPutLanguage
                )}
                name='LANGUAGE'
                menuPlacement='top'
              />
            </div>
          </div>
          <div
            className='flex !w-full flex-wrap items-center gap-[10px]'
            data-tooltip-id='writer-reply-creativity'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='!flex flex-shrink'
              />
            </div>
          </div>
          {/* <div className='flex !w-full  items-center  gap-[10px] min-[1600px]:w-1/2'>
            <Guru
              tool='writer-reply-guru'
              className='w-full justify-between'
              place='top'
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
            />
          </div> */}
        </div>
      </div>
    )
  )
}
