import React, { useEffect, useRef, useState } from 'react'

import { Mic, MicOff, StopCircle, TransitEnterexit } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useLanguages } from 'components/hooks/language'
import { useWindowDimensions } from 'components/hooks/window'
import { detectRtlDirection } from 'helpers'

import ChatBotOptionBar from './chatBotOptionBar'
import { MessageLoader } from 'toolComponents/chat/ChatMessage/MessageLoader'

const ChatBotForm = ({
  isGenerating,
  handleAbort,
  isRecording,
  handleSubmit,
  isTranscriptionLoading,
  handleChangeRecording,
  handleStopRequest,
  setMessage,
  message,
  language,
  handleClearChat,
  userCanAccessHistory,
  setHistoryIsVisible,
  isAutoAudio,
  audioStream,
  setTtsIndex,
  setAudioStream,
  setIsAutoAudio,
  setLanguage,
  widget,
  isSearchWeb,
  isSearchWebAllowed,
  setSearchWeb,
}) => {
  const changeWritingDirection = detectRtlDirection(language)
  const { width } = useWindowDimensions()
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslate()
  const { languages } = useLanguages()

  const textAreaRef = useRef()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleVolumeOff = () => {
    setIsAutoAudio(false)
    setAudioStream()
    setTtsIndex()
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (width > 460) textAreaRef.current?.focus()
    }, 50)
    return () => clearTimeout(timeout)
  }, [width])

  return (
    <div className='bg-brand-grey-bg w-full'>
      <div className='flex w-full flex-col items-center gap-[10px] p-2 py-3 sm:gap-[16px] sm:p-4 md:px-[30px] md:py-[40px] lg:gap-[24px] 2xl:px-[72px] 2xl:py-[40px]'>
        {/* Input window */}
        <div
          id='chat-input'
          className='xs:gap-2 xs:p-2 flex w-full max-w-[1000px] overflow-x-hidden rounded-[4px] bg-black/5 shadow-[0_0_50px_#00000030,0_0_10px_#00000030]'
        >
          <div className='xs:rounded-[4px] flex flex-1 rounded-l-[4px] bg-white'>
            <div className='flex h-full w-full flex-1 flex-col'>
              {isRecording && !message.length ? (
                <div className='text-brand-body xs:pl-4 xs:pt-5 h-full w-full pl-2 pt-3 text-[15px] font-medium leading-[1.2em]'>
                  {t('eleo-chat-tts-placeholder', 'Im listening... You can speak now.')}
                </div>
              ) : (
                <textarea
                  // disabled={isGenerating || isTranscriptionLoading}
                  ref={textAreaRef}
                  className={classNames(
                    'xs:p-[10px] flex-1 resize-none rounded-[4px] p-2 text-[15px] leading-[1.5em] transition-all',
                    (isGenerating || isTranscriptionLoading) && 'bg-[#efefef40]'
                  )}
                  rows={1}
                  spellCheck={false}
                  dir={changeWritingDirection ? 'rtl' : 'ltr'}
                  placeholder={t('eleo-chat-input-placeholder', 'Write a message...')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              )}
            </div>
            <div
              className={classNames(
                'xs:flex-col xs:items-end xs:p-[10px] flex  items-center justify-start p-2 transition-all',
                (isGenerating || isTranscriptionLoading) && 'pointer-events-none bg-[#efefef40]'
              )}
            >
              <div
                id='speech-to-text'
                className={classNames(
                  'flex',
                  (isGenerating || isTranscriptionLoading) && 'pointer-events-none opacity-40'
                )}
                onClick={() => handleChangeRecording()}
              >
                <div
                  className={classNames(
                    'relative flex size-[40px] cursor-pointer items-center justify-center rounded-[4px]  text-[#808080] transition-colors '
                  )}
                >
                  {isTranscriptionLoading ? (
                    <MessageLoader />
                  ) : isRecording ? (
                    <div className='text-brand-body h-full w-full'>
                      <div
                        style={{
                          animation: 'myAnimation 1.5s infinite',
                          animationTimingFunction: 'ease-in-out',
                        }}
                        className='absolute left-1/4 top-1/4 size-5 rounded-full bg-black/5'
                      />
                      <Mic className='absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2' />
                    </div>
                  ) : (
                    <Mic />
                  )}
                </div>
                <div
                  className={classNames(
                    'flex size-[40px] cursor-pointer items-center justify-center rounded-r-[4px] transition-all',
                    isRecording ? '' : 'w-0 opacity-0'
                  )}
                >
                  <MicOff />
                </div>
              </div>
            </div>
          </div>
          <button
            title={t('eleo-chat-tooltip-send', 'Send query')}
            disabled={isTranscriptionLoading}
            className={classNames(
              'xs:rounded-[4px] xs:w-[194px] text-brand-body w-[56px] rounded-r-[4px] bg-[#D4D4D4] py-[21px] text-[15px] font-medium  uppercase leading-[1.2em] ',
              (isGenerating || isTranscriptionLoading) && 'bg-opacity-50 opacity-80'
            )}
            onClick={
              isGenerating
                ? handleAbort
                : isRecording
                  ? () => handleChangeRecording(true)
                  : handleSubmit
            }
          >
            {isGenerating ? (
              <div
                className='flex items-center justify-center gap-[6px] text-[18px]'
                onClick={() => handleStopRequest()}
              >
                {width >= 466 && !widget && (
                  <span className='text-[15px]'>{t('eleo-chat-input-abort', 'stop')}</span>
                )}{' '}
                <StopCircle fontSize='inherit' />
              </div>
            ) : width < 466 || widget ? (
              <span className='text-[22px]'>
                <TransitEnterexit fontSize='inherit' className='rotate-[135deg]' />
              </span>
            ) : (
              t('eleo-chat-input-submit', 'send')
            )}
          </button>
        </div>
        <ChatBotOptionBar
          language={language}
          languages={languages}
          setLanguage={setLanguage}
          handleClose={handleClose}
          isAutoAudio={isAutoAudio}
          audioStream={audioStream}
          handleVolumeOff={handleVolumeOff}
          setIsAutoAudio={setIsAutoAudio}
          userCanAccessHistory={userCanAccessHistory}
          setHistoryIsVisible={setHistoryIsVisible}
          handleClearChat={handleClearChat}
          isSearchWebAllowed={isSearchWebAllowed}
          isSearchWeb={isSearchWeb}
          setSearchWeb={setSearchWeb}
        />
      </div>
    </div>
  )
}

export default ChatBotForm
