import axios from 'axios'
import LibraryItem from './LibraryItem'
import React, { useContext } from 'react'
import { ViewContext } from 'components/lib'
export const ChatbotsList = ({ items, title, fetchItems }) => {
  const context = useContext(ViewContext)

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      fetchItems()
    } catch (err) {
      context.handleError(err)
    }
  }

  return (
    <div className='flex h-full flex-col'>
      <h3 className='pb-4 font-bold'>{title}</h3>
      <div className='wrap flex flex-wrap gap-6'>
        {items.map((item) => {
          return (
            <LibraryItem
              key={item.id}
              id={item.id}
              author={item.author}
              description={item.shortDescription}
              infoTooltip={item.longDescription}
              image={item.avatar}
              name={item.displayName}
              category={item.category}
              isLiked={item.isLiked}
              href={`/chatbot/chat/${item.name}`}
              handleLikeChatbot={handleLikeChatbot}
            />
          )
        })}
      </div>
    </div>
  )
}
