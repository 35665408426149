import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const generationsInitialState = {
  isReady: false,
  clientId: uuidv4(),
  queue: {
    generate: 0,
    modify: 0,
    upscale: 0,
  },
  pending: {
    generate: {},
    modify: {},
    upscale: {},
  },
  finished: {
    generate: {},
    modify: {},
    upscale: {},
  },
}

export const generationsSlice = createSlice({
  name: 'pendingGenerations',
  initialState: generationsInitialState,
  reducers: {
    setIsReady: (state, action) => {
      state.isReady = action.payload
    },
    setQueue: (state, action) => {
      if ('generate' in action.payload) state.queue.generate = action.payload.generate
      if ('modify' in action.payload) state.queue.modify = action.payload.modify
      if ('upscale' in action.payload) state.queue.upscale = action.payload.upscale
    },
    setPending: (state, action) => {
      if (action.payload.generate) state.pending.generate = action.payload.generate
      if (action.payload.modify) state.pending.modify = action.payload.modify
      if (action.payload.upscale) state.pending.upscale = action.payload.upscale
    },
    setFinished: (state, action) => {
      if (action.payload.generate) state.finished.generate = action.payload.generate
      if (action.payload.modify) state.finished.modify = action.payload.modify
      if (action.payload.upscale) state.finished.upscale = action.payload.upscale
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPending, setFinished, setQueue, setIsReady } = generationsSlice.actions

//selectors
export const selectIsReady = (state) => state.pendingGenerations.isReady
export const selectPending = (state) => state.pendingGenerations.pending
export const selectFinished = (state) => state.pendingGenerations.finished
export const selectClientId = (state) => state.pendingGenerations.clientId
export const selectQueue = (state) => state.pendingGenerations.queue

export default generationsSlice.reducer
