import { createSlice } from '@reduxjs/toolkit'
import aiController from 'helpers/AIController'

export const advisorInitialState = {
  history: {
    topic: '',
    advisorContext: '',
    keyWords: '',
    writerLengthOption: 'words',
    templateId: { label: '', value: '' },
    isGuruModeEnabled: false,
    includeSources: false,
    wordLength: { id: '500', checked: true },
    selectOptionValues: {
      author: '',
      language: 'auto',
    },
    showAdvanced: false,
    storyId: { label: '', value: '' },
    model: { ...aiController.getDefaultTextModel(), description: undefined },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const advisorSlice = createSlice({
  name: 'advisor',
  initialState: advisorInitialState,
  reducers: {
    setAdvisorInputHistory: (state, action) => {
      state.history = action.payload
    },
    setAdvisorGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAdvisorInputHistory, setAdvisorGeneratedResultHistory } = advisorSlice.actions

//selectors
export const advisorInputHistory = (state) => state.advisor.history
export const advisorGeneratedResultHistory = (state) =>
  state.advisor.generatedTextHistory.generatedText

export default advisorSlice.reducer
