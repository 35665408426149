import CategoriesSwitch from './CategoriesSwitch'
import { RecentChatbots } from './RecentChatbots'

export const ChatbotLibraryLayout = ({ filter, setFilter }) => {
  return (
    <div className='flex h-full flex-col p-5'>
      <CategoriesSwitch filter={filter} setFilter={setFilter} />
      <RecentChatbots />
    </div>
  )
}
