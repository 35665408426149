import { configureStore } from '@reduxjs/toolkit'
import advisorSlice from 'store/toolInputHistoriesHolder/advisor'
import chatSlice from 'store/toolInputHistoriesHolder/chat'
import checkTextSlice from 'store/toolInputHistoriesHolder/checkText'
import greatIdeasSlice from 'store/toolInputHistoriesHolder/greatIdeas'
import imageCreatorSlice from 'store/toolInputHistoriesHolder/image-creator'
import modifyImageSlice from 'store/toolInputHistoriesHolder/modify-image'
import modifyTextSlice from 'store/toolInputHistoriesHolder/modifyText'
import replyTextSlice from 'store/toolInputHistoriesHolder/reply'
import translatorSlice from 'store/toolInputHistoriesHolder/translator'
import writerSlice from 'store/toolInputHistoriesHolder/writer'
import pendingGenerations from 'store/generations'
import yourStorySlice from 'store/account/yourStory'
import chatBot from 'store/account/chatBot'
import chatbotLibrary from 'store/toolInputHistoriesHolder/chatbot'
import documentContext from 'store/context'

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredPaths: ['advisor', 'writer', 'modifyText', 'replyText', 'documentContext', 'chat'],
    ignoredActionPaths: ['payload.templateId'],
    ignoredActions: ['documentContext/setContext', 'chat/setChatInputHistory'],
  },
}

export const store = configureStore({
  reducer: {
    writer: writerSlice,
    modifyText: modifyTextSlice,
    checkText: checkTextSlice,
    advisor: advisorSlice,
    greatIdeas: greatIdeasSlice,
    translator: translatorSlice,
    chat: chatSlice,
    imageCreator: imageCreatorSlice,
    modifyImage: modifyImageSlice,
    replyText: replyTextSlice,
    yourStory: yourStorySlice,
    chatBot: chatBot,
    chatbotLibrary: chatbotLibrary,
    pendingGenerations: pendingGenerations,
    documentContext: documentContext,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddlewareConfig),
})
