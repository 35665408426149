import { Close } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import { useAccess } from 'components/hooks/access'
import { useWindowDimensions } from 'components/hooks/window'
import { transformSelectOptionValue } from 'helpers'
import { uploadBase64Image } from 'helpers/image'
import { useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { Button, ButtonGroup, Input, ReactSelect, Slider, Toggle } from 'ui'
import ImageDropZone from '../ImageDropZone'
import ToolSelector from '../ToolSelector'
import { styles, types } from '../models/imageOptions'

export default function InpaintLayout({
  state,
  dispatch,
  baseImage,
  imageData,
  handleFileUpload,
  setBaseImage,
  imageSize,
  setImageSize,
}) {
  const { t } = useTranslate()
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')
  const canvas = useRef()
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()

  const [InpaintModalVisible, setInpaintModalVisible] = useState(false)
  const [canvasProps, setCanvasProps] = useState({
    imgSrc: baseImage,
    hideGrid: true,
    lazyRadius: 0,
    brushRadius: 20,
    canvasWidth: 768,
    canvasHeight: 768,
    brushColor: '#00000080',
  })

  // Get image dimentions
  useEffect(() => {
    const img = new Image()
    img.onload = () =>
      setImageSize({
        width: img.width,
        height: img.height,
      })
    img.src = baseImage

    return () => {
      img.onload = null
    }
  }, [baseImage, setImageSize])

  function handleScroll(e) {
    try {
      if (e.deltaY > 0)
        setCanvasProps((prev) => ({
          ...prev,
          brushRadius: Math.min(150, prev.brushRadius + 1),
        }))
      if (e.deltaY < 0)
        setCanvasProps((prev) => ({ ...prev, brushRadius: Math.max(prev.brushRadius - 1, 5) }))
    } catch (err) {
      console.log(err)
    }
  }

  function scaleDimensions(dimensions) {
    let { width, height } = dimensions

    const uiHeight = windowWidth > 1024 ? 180 : 280

    if (width > windowWidth) {
      const tempWidth = windowWidth - 100

      height = Math.round((tempWidth / width) * height)
      width = tempWidth
    }

    if (height + uiHeight > windowHeight) {
      const tempHeight = windowHeight - uiHeight

      width = Math.round((tempHeight / height) * width)
      height = tempHeight
    }

    return { width, height }

    // if (width >= height) {
    //   return {
    //     width: 768,
    //     height: Math.round((768 / width) * height),
    //   }
    // } else {
    //   return {
    //     width: Math.round((768 / height) * width),
    //     height: 768,
    //   }
    // }
  }

  function showInpaintModal() {
    if (!imageSize) return

    const { width, height } = scaleDimensions(imageSize)

    setCanvasProps((prev) => ({
      ...prev,
      imgSrc: baseImage,
      brushRadius: 20,
      canvasWidth: width,
      canvasHeight: height,
      erase: false,
    }))

    setTimeout(() => {
      setInpaintModalVisible(true)
    }, 200)
  }

  async function saveMask() {
    const res = await uploadBase64Image(canvas.current.getDataURL('png', true))
    if (res.item)
      dispatch({
        type: 'SET_MASK',
        payload: res.item,
      })
    setInpaintModalVisible(false)
  }

  return (
    <>
      {/* Image Selector */}
      <ImageDropZone
        baseImage={baseImage}
        mask={state.mask}
        handleFileUpload={handleFileUpload}
        setBaseImage={setBaseImage}
        handleImageClick={showInpaintModal}
      />

      <ToolSelector activeTool={state.tool} dispatch={dispatch} />

      <form className='flex flex-1 flex-col justify-between text-[13px]'>
        {/* Form contents */}
        <div
          className='grid gap-x-3 gap-y-4 px-4 py-5 2xl:gap-x-4'
          style={{
            gridTemplateColumns: 'auto minmax(0, 1fr) auto minmax(0, 1fr)',
          }}
        >
          <div className='pt-2 font-medium'>
            <T keyName='eleo-write-text-keywords'>Keywords</T>
          </div>
          <div
            className='col-span-3 lg:col-span-1'
            data-tooltip-id='images-modifyImage-description'
          >
            <Input
              className='w-full'
              placeholder={
                imageData
                  ? t(
                      'eleo-image-modify-description-placeholder-1',
                      'Describe what you want to see.'
                    )
                  : t('eleo-image-modify-description-placeholder-2', 'Describe your image')
              }
              value={state.prompt}
              onChange={(val) =>
                dispatch({
                  type: 'SET_PROMPT',
                  payload: val,
                })
              }
            />
          </div>
          <label className='my-auto font-medium'>
            <T keyName='eleo-tool-create-image-avoid'>Avoid</T>
          </label>
          <div className='col-span-3 lg:col-span-1' data-tooltip-id='images-modifyImage-avoid'>
            <Input
              className='w-full'
              placeholder={t(
                'eleo-modify-image-avoid-placeholder',
                'What would you like to avoid?'
              )}
              onChange={(val) =>
                dispatch({
                  type: 'SET_NEGATIVE_PROMPT',
                  payload: val,
                })
              }
              value={state.negativePrompt}
            />
          </div>

          {/* <label className={classNames('my-auto font-medium')}>
            <T keyName='eleo-write-text-template'>Template</T>
          </label>
          <div
            className={classNames('col-span-3 my-auto text-[16px] lg:col-span-1')}
            {...(!allowStandard && { onClick: showUpgradePlanModal })}
          >
            <ReactSelect
              disabled={!allowStandard}
              options={templateSelection}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={templateSelection.find((item) => item.value === state.template) || ''}
              setSelectedValue={(val) =>
                dispatch({
                  type: 'SET_TEMPLATE',
                  payload: val.value,
                })
              }
              showDeleteIcon
              deleteAction={(templateId) => showDeleteConfirmationModal(templateId)}
            />
          </div> */}

          <div className='my-auto overflow-hidden font-medium'>
            <T keyName='eleo-tool-create-image-type'>Type</T>
          </div>
          <div
            className='col-span-3 my-auto text-[16px] lg:col-span-1'
            data-tooltip-id='images-modifyImage-type'
          >
            <ReactSelect
              className='w-full '
              options={types}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(types, state.type, types[0])}
              setSelectedValue={(event) =>
                dispatch({
                  type: 'SET_TYPE',
                  payload: event.value,
                })
              }
            />
          </div>

          <div className='my-auto overflow-hidden font-medium'>
            <T keyName='eleo-tool-create-image-amount'>Amount</T>
          </div>
          <div className='col-span-3 lg:col-span-1' data-tooltip-id='images-modifyImage-amount'>
            <ButtonGroup
              className='w-full'
              options={[1, 2, 3, 4].map((item) => ({
                value: item,
                label: item,
              }))}
              buttonClass='overflow-x-hidden'
              value={state.samples}
              onClick={(event) =>
                dispatch({
                  type: 'SET_SAMPLES',
                  payload: event.target.value,
                })
              }
            />
          </div>

          <div className='my-auto overflow-hidden font-medium'>
            <T keyName='eleo-write-text-style'>Style</T>
          </div>
          <div className='col-span-3' data-tooltip-id='images-modifyImage-style'>
            {/* <ButtonGroup
        className="w-full"
        options={styles}
        value={imageStyle}
        buttonClass="overflow-x-hidden"
        onClick={(event) => setImageStyle(event.target.value)}
      /> */}
            <ReactSelect
              options={styles}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={styles.find((style) => style.value === state.style) || null}
              setSelectedValue={(val) =>
                dispatch({
                  type: 'SET_STYLE',
                  payload: val.value,
                })
              }
              menuPlacement='top'
            />
          </div>

          <div
            className='col-span-4 pr-3 lg:col-span-2'
            data-tooltip-id='images-modifyImage-quality'
          >
            <div className='my-auto font-medium'>
              <T keyName='eleo-tool-create-image-quality'>Quality</T>
            </div>
            <Slider
              value={state.quality}
              onChange={(val) =>
                dispatch({
                  type: 'SET_QUALITY',
                  payload: val,
                })
              }
            />

            <div className='text-brand-secondary flex justify-between text-sm'>
              <div>
                <T keyName='eleo-tool-create-image-quality-poor'>Poor</T>
              </div>
              <div>
                <T keyName='eleo-tool-create-image-quality-high'>High</T>
              </div>
            </div>
          </div>

          <div className='col-span-4 lg:col-span-2' data-tooltip-id='images-modifyImage-creativity'>
            <div className='my-auto font-medium'>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </div>
            <Slider
              value={state.creativity}
              onChange={(val) =>
                dispatch({
                  type: 'SET_CREATIVITY',
                  payload: val,
                })
              }
            />

            <div className='text-brand-secondary flex justify-between text-sm'>
              <div>
                <T keyName='eleo-tool-great-ideas-creativity-literal'>Literal</T>
              </div>
              <div>
                <T keyName='eleo-tool-great-ideas-creativity-creative'>Creative</T>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Inpainting Modal */}
      {InpaintModalVisible && (
        <div
          className='fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-25'
          onClick={() => setInpaintModalVisible(false)}
        >
          <div
            className='relative flex min-h-[50%] min-w-[50%] flex-col rounded-lg bg-white p-4'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='mb-4 flex flex-col items-center justify-center'>
              <div className='mb-4 text-3xl font-semibold'>
                <T keyName='eleo-inpainting'>Inpainting</T>
              </div>
              <div className='cursor-crosshair' onWheel={handleScroll}>
                <CanvasDraw ref={canvas} {...canvasProps} />
              </div>
            </div>

            <div className='items-end justify-between gap-4 px-2 lg:flex'>
              <div className='flex-1'>
                <div>
                  <T keyName='eleo-brush-size'>Brush Size</T>
                </div>
                <Slider
                  value={canvasProps.brushRadius}
                  onChange={(val) => setCanvasProps((prev) => ({ ...prev, brushRadius: val }))}
                  min={5}
                  max={150}
                />
              </div>
              <div className='flex flex-1 flex-wrap items-center justify-between gap-4 md:flex-nowrap'>
                <div className='flex items-center gap-2'>
                  <Button variant='small' color='grey' onClick={() => canvas.current.undo()}>
                    <T keyName='eleo-undo'>Undo</T>
                  </Button>
                  <Button variant='small' color='grey' onClick={() => canvas.current.clear()}>
                    <T keyName='eleo-clear'>Clear</T>
                  </Button>
                  <div>
                    <T keyName='eleo-erase'>Erase:</T>
                  </div>
                  <Toggle
                    isChecked={canvasProps.erase}
                    setIsChecked={(val) => setCanvasProps((prev) => ({ ...prev, erase: val }))}
                  />
                </div>
                <div className='flex flex-1 justify-end'>
                  <Button variant='solid' color='green' className='ml-auto' onClick={saveMask}>
                    <T keyName=''>Save</T>
                  </Button>
                </div>
              </div>
            </div>

            <Close
              className='absolute right-2 top-2 cursor-pointer'
              onClick={() => setInpaintModalVisible(false)}
            />
          </div>
        </div>
      )}
    </>
  )
}
