import { createSlice } from '@reduxjs/toolkit'
import { resolutions } from 'toolComponents/image/models/imageSizes'

export const imageCreatorInitialState = {
  imageFormValues: {
    prompt: '',
    keywords: '',
    negativePrompt: '',
    quality: '5',
    creativity: 5,
    style: 'Realistic',
    type: 'Anything',
    samples: 2,
    chosenProportions: resolutions.sd15[0].value,
    template: '',
    allowStyles: true,
    isOpen: true,
  },
  imagesHolderValues: {
    history: [],
    selectedImage: '',
    currentIndex: -1,
    pendingGeneration: {},
    isLoading: false,
    isImageInQueue: false,
  },
}

export const imageCreatorSlice = createSlice({
  name: 'imageCreator',
  initialState: imageCreatorInitialState,
  reducers: {
    setImageCreatorInputHistory: (state, action) => {
      state.imageFormValues = action.payload
    },
    setImageHolderHistoryValues: (state, action) => {
      state.imagesHolderValues = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setImageCreatorInputHistory, setImageHolderHistoryValues } =
  imageCreatorSlice.actions

//selectors
export const selectImageCreatorInputHistory = (state) => state.imageCreator.imageFormValues
export const selectImageHolderHistoryValues = (state) => state.imageCreator.imagesHolderValues

export default imageCreatorSlice.reducer
