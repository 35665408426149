import { HelpTooltip } from './helpTooltip'
import { helpMessages } from './messages/helpMessages'
import { T, useTolgee } from '@tolgee/react'

export function HelpTooltips(props) {
  const t = useTolgee()
  const lang = t.getLanguage()
  const elements = []
  const tool = props.tool
  const group = props.group

  if (!tool) return null

  let messages
  let prefix = ''

  if (group) {
    messages = helpMessages[group][tool]
    prefix = group + '-'
  } else {
    messages = helpMessages[tool]
  }

  for (let key in messages) {
    const tip = messages[key]
    if (!tip) continue

    const title = <T keyName={tip.tolgee_id + '-title'}>{tip.title}</T>
    const content = <T keyName={tip.tolgee_id + '-content'}>{tip.content}</T>
    const actions = tip.actions
      ? tip.actions.map((action) => (
          <a
            href={action.links?.[lang]}
            key={action.name}
            className='underline'
            target='_blank'
            rel='noreferrer'
          >
            <T keyName={'tooltip-link-' + action.name}>{action.text}</T>
          </a>
        ))
      : undefined

    let id = prefix + tool + '-' + key

    elements.push(
      <HelpTooltip
        key={id}
        id={id}
        title={title}
        content={content}
        clickable={!!actions}
        actions={actions}
        showAlways={key === 'saveTemplate'}
      />
    )
  }

  return <>{elements}</>
}
