import { createSlice } from '@reduxjs/toolkit'
import aiController from 'helpers/AIController'
import { CheckForInputData } from 'toolComponents/writer/writer-options-data'

export const checkTextInitialState = {
  history: {
    myText: '',
    creativity: 'Medium',
    language: '',
    isGuruModeEnabled: false,
    selectOptionValues: {
      language: '',
      check: CheckForInputData[0].value,
    },
    model: { ...aiController.getDefaultTextModel(), description: undefined },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const checkTextSlice = createSlice({
  name: 'checkText',
  initialState: checkTextInitialState,
  reducers: {
    setCheckTextInputHistory: (state, action) => {
      state.history = action.payload
    },
    setCheckTextGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCheckTextInputHistory, setCheckTextGeneratedResultHistory } =
  checkTextSlice.actions

//selectors
export const checkTextInputHistory = (state) => state.checkText.history
export const checkTextGeneratedResultHistory = (state) =>
  state.checkText.generatedTextHistory.generatedText

export default checkTextSlice.reducer
