/***
 *
 *   Gallery
 *   Stores user's saved images
 *
 **********/

// import React from 'react'
import { Animate } from 'components/lib'
import GalleryLayout from 'toolComponents/gallery/galleryLayout'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'

export function Gallery() {
  const context = useContext(ViewContext)

  const [items, setItems] = useState([])

  const fetchItems = useCallback(async () => {
    try {
      const res = await axios.get('/api/gallery')
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  return (
    <Animate type='pop'>
      <GalleryLayout items={items} refreshData={fetchItems} />
    </Animate>
  )
}
