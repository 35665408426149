import { Mic, MicOff, StopCircle, TransitEnterexit } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import imageCompression from 'browser-image-compression'
import classNames from 'classnames'
import { detectRtlDirection, errorToast } from 'helpers'
import MicRecorder from 'mic-recorder-to-mp3'
import { useEffect, useRef, useState } from 'react'
import { useGetTranscription } from 'toolComponents/generic/audio/transcription'
import { MessageContext } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import { MessageContextItem } from './MessageContext/item'
import { MessageContextModal } from './MessageContext/modal'
import { OptionsBar } from './OptionsBar'
import { Loader } from 'components/lib'
import { MessageLoader } from './ChatMessage/MessageLoader'

const docTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const recorder = new MicRecorder({
  bitRate: 128,
})

export function ChatForm({
  isGenerating,
  handleSubmit,
  handleAbort,
  state,
  dispatch,
  handleClearContext,
  documentContext,
  audioStream,
  setAudioStream,
  handleStopRequest,
  messageContext,
  isHistoryVisible,
  width,
  setTtsIndex,
  setShowWebCam,
}) {
  const { t } = useTranslate()
  const [modal, setModal] = useState({ visible: false, content: '' })
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const getTranscription = useGetTranscription()
  const changeWritingDirection = detectRtlDirection(state.language.value)
  const [hasCamera, setHasCamera] = useState(false)

  const textAreaRef = useRef()
  useEffect(() => {
    // Doesn't work without a timeout
    const timeout = setTimeout(() => {
      if (width > 460) textAreaRef.current?.focus()
    }, 50)
    return () => clearTimeout(timeout)
  }, [width])

  // Remove uploaded images when switching to model which doesnt support image input
  useEffect(() => {
    if (!state.model.supportsImages) messageContext.removeDocumentsOfType('image')
  }, [state.model])

  function handleChangeRecording(sendWhenReady = false) {
    if (isGenerating || isTranscriptionLoading) return

    if (isRecording) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setIsRecording(false)
          if (buffer.length < 25) return

          getTranscription({
            recordingBlob: blob,
            setIsLoading: setIsTranscriptionLoading,
            callback: (text) => {
              sendWhenReady
                ? handleSubmit(null, state.inputValue + (state.inputValue.length ? ' ' : '') + text)
                : dispatch({ type: 'APPEND_INPUT', payload: text })
            },
            ...(state.language.value !== 'auto' && { language: state.language?.code }),
          })
        })
    } else
      recorder
        .start()
        .then(() => setIsRecording(true))
        .catch((err) => {
          if (err.name === 'NotAllowedError')
            errorToast(
              t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
            )
          else errorToast(t('eleo-error-generic'))
        })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleOpenModal = (type) => {
    setModal({ visible: true, content: type })
  }

  async function handleImageUpload(e) {
    e.preventDefault()
    const file = e.dataTransfer?.files[0] || e.target.files[0]
    if (!file) return

    // Allow only 1 image at a time
    messageContext.removeDocumentsOfType('image')

    const id = uuidv4()
    messageContext.addDocument({
      id: id,
      label: file.name,
      content: null,
      type: 'image',
      invalid: true,
      metadata: { type: file.type },
    })

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(file, options)

      const reader = new FileReader()
      reader.onload = () => {
        const imageBlob = new Blob([reader.result], { type: file.type })
        messageContext.setDocContext((prev) =>
          prev.map((item) =>
            item.id === id
              ? { ...item, content: URL.createObjectURL(imageBlob), invalid: false }
              : item
          )
        )
      }
      reader.onerror = function () {
        errorToast(t('eleo-error-image-upload', 'Failed to upload your image'))
      }

      reader.readAsArrayBuffer(compressedFile)
    } catch (error) {
      console.log(error)
    } finally {
      e.target.value = null
    }
  }

  async function handleDocumentUpload(e) {
    e.preventDefault()

    const files = e.target.files
    if (!files.length) return

    for (const file of files) {
      if (!docTypes.includes(file.type)) {
        return errorToast(
          t('eleo-your-story-document-not-supported', 'This document type is not supported')
        )
      }

      messageContext.addDocument({
        label: file.name,
        type: 'document',
        id: uuidv4(),
        content: file,
        metadata: { type: file.type },
      })
    }

    // Clear file input
    e.target.value = null
  }

  async function checkForCamera() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter((device) => device.kind === 'videoinput')
      return videoDevices.length > 0
    } catch (error) {
      errorToast(t('eleo-generic-error'))
    }
  }

  async function handleOpenCamera() {
    setShowWebCam(true)
  }

  const detectCamera = async () => {
    const hasCamera = await checkForCamera()
    if (hasCamera) {
      setHasCamera(hasCamera)
    }
  }

  useEffect(() => {
    detectCamera()
  }, [])

  useEffect(() => {
    function handlePaste(e) {
      const items = e.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const file = items[i].getAsFile()
          handleImageUpload({ preventDefault: () => {}, target: { files: [file] } })
          break
        }
      }
    }
    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [])

  return (
    <>
      <div className='bg-brand-violet/10 w-full'>
        <div className='flex w-full flex-col items-center gap-[10px] p-2 py-3 sm:gap-[16px] sm:p-4 md:px-[30px] md:py-[30px] lg:gap-[24px] 2xl:px-[72px] 2xl:py-[40px]'>
          {/* Input window */}
          <div
            id='chat-input'
            className='xs:gap-2 xs:p-2 flex w-full max-w-[1000px] overflow-x-hidden rounded-[4px] bg-[#7575FF]/80 shadow-[0_0_50px_#7575FF40,0_0_10px_#6363E550]'
          >
            <div className='xs:rounded-[4px] flex flex-1 rounded-l-[4px] bg-white'>
              <div className='flex h-full w-full flex-1 flex-col'>
                {isRecording && !state.inputValue.length ? (
                  <div className='text-brand-violet xs:pl-4 xs:pt-5 h-full w-full pl-2 pt-3 text-[15px] font-medium leading-[1.2em]'>
                    {t('eleo-chat-tts-placeholder', 'Im listening... You can speak now.')}
                  </div>
                ) : (
                  <textarea
                    // disabled={isGenerating || isTranscriptionLoading}
                    ref={textAreaRef}
                    className={classNames(
                      'xs:p-[10px] flex-1 resize-none rounded-[4px] p-2 text-[15px] leading-[1.5em] transition-all',
                      (isGenerating || isTranscriptionLoading) && 'bg-[#efefef40]',
                      Boolean(messageContext.docContext.length) && 'mb-[6px] h-[54px] pb-0'
                    )}
                    rows={1}
                    spellCheck={false}
                    dir={changeWritingDirection ? 'rtl' : 'ltr'}
                    placeholder={t('eleo-chat-input-placeholder', 'Write a message...')}
                    value={state.inputValue}
                    onChange={(e) => dispatch({ type: 'SET_INPUT', payload: e.target.value })}
                    onKeyDown={handleKeyDown}
                  />
                )}

                {/* Documents from messageContext */}
                {Boolean(messageContext.docContext.length) && (
                  <div
                    className={classNames(
                      'xs:p-[10px] flex flex-wrap items-center gap-x-2 gap-y-1 p-2 pt-0',
                      (isGenerating || isTranscriptionLoading) &&
                        'pointer-events-none bg-[#efefef40]'
                    )}
                  >
                    {messageContext.docContext.map((item) => (
                      <MessageContextItem
                        key={item.id}
                        item={item}
                        messageContext={messageContext}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  'xs:flex-col xs:items-end xs:p-[10px] flex items-start justify-start p-2 transition-all',
                  (isGenerating || isTranscriptionLoading) && 'pointer-events-none bg-[#efefef40]'
                )}
              >
                <div
                  id='speech-to-text'
                  className={classNames(
                    'flex',
                    (isGenerating || isTranscriptionLoading) && 'pointer-events-none opacity-40'
                  )}
                  onClick={() => handleChangeRecording()}
                >
                  <div
                    data-tooltip-id={width >= 740 && `eleo-tooltip`}
                    data-tooltip-keyname='eleo-chat-tooltip-stt'
                    data-tooltip-hidden={isRecording}
                    className={classNames(
                      'text-brand-gray relative flex size-[40px] cursor-pointer items-center justify-center rounded-[4px] bg-black/5 transition-colors ',
                      isRecording ? 'rounded-r-none' : 'hover:bg-brand-violet hover:text-white'
                    )}
                  >
                    {isTranscriptionLoading ? (
                      <MessageLoader />
                    ) : isRecording ? (
                      <div className='h-full w-full text-white'>
                        <div
                          style={{
                            animation: 'myAnimation 1.5s infinite',
                            animationTimingFunction: 'ease-in-out',
                          }}
                          className='bg-brand-violet absolute left-1/4 top-1/4 size-5 rounded-full'
                        />
                        <Mic className='absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2' />
                      </div>
                    ) : (
                      <Mic />
                    )}
                  </div>
                  <div
                    className={classNames(
                      'text-brand-gray flex size-[40px] cursor-pointer items-center justify-center rounded-r-[4px] bg-black/5 transition-all',
                      isRecording ? '' : 'w-0 opacity-0'
                    )}
                  >
                    <MicOff />
                  </div>
                </div>

                <MessageContext
                  id='message-context'
                  translations={{
                    header: t('eleo-chat-message-context-header', 'Add context to this message'),
                    documentsLabel: t('eleo-chat-message-context-document', 'Document'),
                    imagesLabel: t('eleo-chat-message-context-image', 'Image'),
                    websitesLabel: t('eleo-chat-message-context-website', 'Website'),
                    storiesLabel: t('eleo-feature-your-story', 'Your Story'),
                    cameraLabel: t('eleo-feature-take-a-picture', 'Take a picture'),
                  }}
                  tooltipId={width >= 740 && `eleo-tooltip`}
                  tooltipKeyname={`eleo-chat-tooltip-message-context`}
                  hideTooltip={modal.visible}
                  isDisabled={isGenerating || isTranscriptionLoading}
                  handleOpenModal={handleOpenModal}
                  enableImages={state.model.supportsImages}
                  handleImageUpload={handleImageUpload}
                  handleDocumentUpload={handleDocumentUpload}
                  handleShowWebCam={handleOpenCamera}
                  disableWebsites={
                    messageContext.docContext?.filter((doc) => doc.type === 'website').length >= 5
                  }
                  showCameraContext={hasCamera}
                />
              </div>
            </div>
            <button
              title={t('eleo-chat-tooltip-send', 'Send query')}
              disabled={isTranscriptionLoading}
              className={classNames(
                'bg-brand-violet xs:rounded-[4px] xs:w-[194px] w-[56px] rounded-r-[4px] text-[15px] uppercase leading-[1.2em] text-white',
                (isGenerating || isTranscriptionLoading) && 'bg-opacity-50 opacity-80'
              )}
              onClick={
                isGenerating
                  ? handleAbort
                  : isRecording
                    ? () => handleChangeRecording(true)
                    : handleSubmit
              }
            >
              {isGenerating ? (
                <div
                  className='flex items-center justify-center gap-[6px] text-[18px]'
                  onClick={() => handleStopRequest()}
                >
                  {width >= 466 && (
                    <span className='text-[15px]'>{t('eleo-chat-input-abort', 'stop')}</span>
                  )}{' '}
                  <StopCircle fontSize='inherit' />
                </div>
              ) : width < 466 ? (
                <span className='text-[22px]'>
                  <TransitEnterexit fontSize='inherit' className='rotate-[135deg]' />
                </span>
              ) : (
                t('eleo-chat-input-submit', 'send')
              )}
            </button>
          </div>

          {/* Options bar */}
          <OptionsBar
            state={state}
            dispatch={dispatch}
            documentContext={documentContext}
            handleClearContext={handleClearContext}
            width={width}
            isHistoryVisible={isHistoryVisible}
            setAudioStream={setAudioStream}
            audioStream={audioStream}
            setTtsIndex={setTtsIndex}
          />
        </div>
      </div>
      {modal.visible && (
        <MessageContextModal
          modal={modal}
          setModal={setModal}
          messageContext={messageContext}
          width={width}
        />
      )}
    </>
  )
}
