import CategoriesSwitch from './CategoriesSwitch'
import { ChatbotsList } from './ChatbotsList'

export const ChatbotLibraryListLayout = ({ items, title, fetchItems, filter, setFilter }) => {
  return (
    <div className='flex h-full flex-col p-5'>
      <CategoriesSwitch filter={filter} setFilter={setFilter} />
      <ChatbotsList items={items} title={title} fetchItems={fetchItems} />
    </div>
  )
}
