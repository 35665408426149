import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Loader, ViewContext } from 'components/lib'
import { useContext } from 'react'
import { useTranslate } from '@tolgee/react'
import { ChatbotsList } from './ChatbotsList'
import { Button } from 'ui'
import { Link } from 'react-router-dom'

export const RecentChatbots = () => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [pageSize, setPageSize] = useState(5)
  const { t } = useTranslate()

  const fetchItems = useCallback(async () => {
    try {
      const res = await axios.get(`/api/chat-bot/library?page=1&pageSize=${pageSize}`)
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [context, pageSize])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, pageSize])

  const title = t('recently-added-chatbots-title', 'Recently added Chatbots')

  if (!items || items.length === 0) return <Loader />
  return (
    <div className='flex flex-col gap-10 bg-slate-50'>
      <ChatbotsList items={items} title={title} fetchItems={fetchItems} />
      {items.length === pageSize && (
        <Button
          onClick={() => setPageSize((prev) => prev + 5)}
          color='gray'
          className='w-fit max-w-24'
        >
          more
        </Button>
      )}
    </div>
  )
}
