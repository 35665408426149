import {
  ChevronLeft,
  ChevronRight,
  ContentCopy,
  Refresh,
  Share,
  MicNone,
  VolumeUpOutlined,
  Stop,
} from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { useStream } from 'components/hooks/stream'
import { useWindowDimensions } from 'components/hooks/window'
import { SocialShare, ViewContext } from 'components/lib'
import { CopyToClipBoard, detectRtlDirection, errorToast } from 'helpers'
import { CircleNotch } from 'phosphor-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Selection from 'selection-popover'
import { setTranslatorGeneratedResultHistory } from 'store/toolInputHistoriesHolder/translator'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import TranslatorSettings from 'toolComponents/translator/TranslatorSettings'
import { Button } from 'ui'
import { useGetTranscription } from 'toolComponents/generic/audio/transcription'
import { languages } from 'helpers'
import MicRecorder from 'mic-recorder-to-mp3'
import { Link } from 'react-router-dom'

const recorder = new MicRecorder({
  bitRate: 128,
})

export default function TranslatorPanel({
  showHistory,
  setShowHistory,
  fetchHistory,
  state,
  dispatch,
  outputValue,
  setOutputValue,
}) {
  const { t } = useTranslate()
  const dispatchAction = useDispatch()
  const context = useContext(ViewContext)
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const { width } = useWindowDimensions()
  const [isRecording, setIsRecording] = useState(false)
  const [selectedRange, setSelectedRange] = useState()
  const [selectedWordIndex, setSelectedWordIndex] = useState()
  const [audioStream, setAudioStream] = useState({ name: '', data: null })
  const getTranscription = useGetTranscription()
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState(false)
  const [isTTSLoading, setIsTTSLoading] = useState({ name: '', status: false })
  const [selectedText, setSelectedText] = useState('')
  const changeOutputWritingDirection = detectRtlDirection(state.outputLanguage)
  const [loading, setLoading] = useState({ name: '', status: false })
  const changeInputWritingDirection = detectRtlDirection(state.inputLanguage)
  const [outputKey, refreshOutput] = useState(new Date())
  const [stateHistory, setStateHistory] = useState([])

  let [folded, setFolded] = useState(false)
  if (width < 1400 && showHistory) folded = false

  const parentRef = useRef(null)
  const wordSelectRef = useRef() // double and triple click timeout tracking
  const clickCountRef = useRef(0) // double and triple click count tracking
  const contentRef = useRef() // Holds the value of output before keypress (used for ctrl+z)

  useEffect(() => {
    if (!completionState.message) return
    setOutputValue(completionState.message)
  }, [completionState.message, setOutputValue])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  async function handleSubmit(loadingName, input) {
    if (state.inputLanguage === state.outputLanguage) {
      console.log('Cant translate to the same language')
      return
    }
    if (!input && !state.inputValue) return
    refreshOutput(new Date())

    const url = '/api/ai/translate'
    const data = {
      input: input ?? state.inputValue,
      from_lang: state.inputLanguage,
      to_lang: state.outputLanguage,
      is_native: state.isNative,
      isGuruModeEnabled: state.isGuruModeEnabled,
      model: state.model?.value,
      yourStory: state.yourStory?.value,
      kwargs: { stream: true },
    }

    try {
      setLoading({ name: loadingName || 'translate', status: true })
      setOutputValue('')
      setAudioStream({ name: '', data: null })

      const completion = await fetchCompletion(url, data)
      if (state.isAudio) handleTTS(completion)

      setTimeout(() => {
        // Wait 1s for db to update
        fetchHistory()
      }, 1000)
    } catch (error) {
      console.log(error)
      if (error.name === 'LimitError') {
        errorToast(
          <T keyName='eleo-word-limit-exceeded'>
            You have run out of words. Visit your account page to buy more.
          </T>
        )
      } else errorToast(<T keyName='eleo-generic-error'>Something went wrong. Try again.</T>)
    } finally {
      setLoading({ name: loadingName || 'translate', status: false })
    }
  }

  const getTextContext = () => {
    if (!selectedText) return

    const contentEditable = parentRef.current

    const range = document.createRange()

    range.selectNodeContents(contentEditable)
    range.setStart(selectedRange.startContainer, selectedRange.startOffset)
    range.setEnd(selectedRange.endContainer, selectedRange.endOffset)

    const textBeforeRange = range.cloneRange()
    textBeforeRange.selectNodeContents(contentEditable)
    textBeforeRange.setEnd(range.startContainer, range.startOffset)

    const textAfterRange = range.cloneRange()
    textAfterRange.selectNodeContents(contentEditable)
    textAfterRange.setStart(range.endContainer, range.endOffset)

    let spaceAfter = false
    let selectedTextValue = range.toString()
    if (selectedTextValue[selectedTextValue.length - 1] === ' ') {
      spaceAfter = true
      selectedTextValue = selectedTextValue.trimEnd()
    }

    const textBeforeSelection = textBeforeRange.toString()
    const textAfterSelection = textAfterRange.toString()
    const textContext =
      textBeforeSelection +
      "'''" +
      selectedTextValue +
      (spaceAfter ? "''' " : "'''") +
      textAfterSelection

    return textContext
  }

  const handleChangeSelectedText = (replacementText) => {
    const contentEditable = parentRef.current
    // const selectedRange = window.getSelection().getRangeAt(0)

    // Check if the selection is within the contentEditable div
    if (!contentEditable.contains(selectedRange.startContainer)) {
      parentRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          code: 'Escape',
          bubbles: true,
          cancelable: true,
        })
      )
      return console.log('Range not found')
    }
    handleUpdateHistory(parentRef.current?.innerText)

    const range = selectedRange

    // Add a space after if selection has it and the replacement text doesn't
    let spaceAfter = false
    let spaceBefore = false
    if (
      range.toString()[range.toString().length - 1] === ' ' &&
      replacementText[replacementText.length - 1] !== ' '
    )
      spaceAfter = true
    if (range.toString()[0] === ' ' && replacementText[0] !== ' ') spaceBefore = true

    const newNode = document.createTextNode(
      (spaceBefore ? ' ' : '') + replacementText + (spaceAfter ? ' ' : '')
    )

    // Replace the selected content with the new text
    range.deleteContents()
    range.insertNode(newNode)

    // Move the cursor to the end of the inserted text
    range.setStartAfter(newNode)
    range.collapse(true)

    // Update the selection
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

    dispatchAction(setTranslatorGeneratedResultHistory(parentRef?.current?.textContent))

    refreshOutputStructure()
  }

  function flipLanguages() {
    const oldInputLang = state.inputLanguage
    const oldOutputLang = state.outputLanguage
    dispatch({ type: 'SET_INPUT_LANGUAGE', payload: oldOutputLang })
    dispatch({ type: 'SET_OUTPUT_LANGUAGE', payload: oldInputLang })

    const oldInputVal = state.inputValue
    const oldOutputVal = outputValue
    dispatch({ type: 'SET_INPUT', payload: oldOutputVal })
    setOutputValue(oldInputVal)
  }

  function resetFields() {
    dispatch({ type: 'RESET' })
    setOutputValue('')
    setAudioStream({ name: '', data: null })
    dispatchAction(setTranslatorGeneratedResultHistory(parentRef?.current?.textContent))
  }

  const handleSelectionChange = () => {
    const selection = window.getSelection()
    if (selection && selection.toString()) {
      // Text is selected within the container
      setSelectedText(selection.toString())
      setSelectedRange(selection.getRangeAt(0))
    }
  }

  const handleDeleteSelectedText = () => {
    const contentEditable = parentRef.current

    // Check if the selection is within the contentEditable div
    if (!contentEditable.contains(selectedRange.startContainer)) {
      parentRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          code: 'Escape',
          bubbles: true,
          cancelable: true,
        })
      )
      return console.log('Range not found')
    }

    handleUpdateHistory(parentRef.current?.innerText)

    // Create a range for the selected content
    const selectedContentRange = selectedRange.cloneRange()

    // Extract the selected content
    selectedContentRange.deleteContents()

    // Collapse the range to the end to keep the cursor at the correct position
    selectedContentRange.collapse(false)

    // Update the selection with the collapsed range
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(selectedContentRange)
    // handleSaveGeneratedTextHistory(parentRef?.current?.innerHTML)
    // setOutputValue(parentRef?.current?.textContent)
    dispatchAction(setTranslatorGeneratedResultHistory(parentRef?.current?.textContent))
    refreshOutputStructure()
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(content)}
              url={window.location.origin}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  const handleTTS = async (text, name) => {
    const data = {
      input: text,
    }

    try {
      setIsTTSLoading({ name: name, status: true })
      const res = await fetch(axios.defaults.baseURL + '/api/utility/tts', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: axios.defaults.headers.common['Authorization'],
        },
      })

      if (!res.ok) {
        const data = await res.json()
        if (data.code === 'TEXT_TOO_LONG')
          return errorToast(
            <T keyName='eleo-error-tts-tool-long'>
              This message is too long. Try a shorter message.
            </T>
          )
        return errorToast(
          <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
        )
      }

      setAudioStream({ name: name ?? '', data: res.body })
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsTTSLoading({ name: '', status: false })
    }
  }

  function handleChangeRecording() {
    if (isRecording) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setIsRecording(false)
          if (buffer.length < 25) return

          const lang = languages.find((l) => l.value === state.inputLanguage)

          getTranscription({
            recordingBlob: blob,
            setIsLoading: setIsTranscriptionLoading,
            callback: (text) => {
              dispatch({ type: 'SET_INPUT', payload: text })
              handleSubmit(null, text)
            },
            ...(lang.value !== 'auto' && { language: lang.code }),
          })
        })
    } else {
      setAudioStream({ name: '', data: null })
      recorder
        .start()
        .then(() => setIsRecording(true))
        .catch((err) => {
          if (err.name === 'NotAllowedError')
            errorToast(
              t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
            )
          else errorToast(t('eleo-error-generic'))
        })
    }
  }

  const handleSelectWord = (e, index) => {
    clickCountRef.current += 1

    if (clickCountRef.current === 2) {
      // Handle double click
      clearTimeout(wordSelectRef.current)
      wordSelectRef.current = setTimeout(() => {
        clickCountRef.current = 0
      }, 300)
      return
    } else if (clickCountRef.current === 3) {
      // Handle triple click
      clearTimeout(wordSelectRef.current)
      clickCountRef.current = 0
      return
    }

    // Handle single click
    let savedRange
    const selection = window.getSelection()
    if (selection.getRangeAt && selection.rangeCount) {
      savedRange = selection.getRangeAt(0)
    }

    const range = document.createRange()
    selection.removeAllRanges()
    range.selectNodeContents(e.target)
    selection.addRange(range)
    setSelectedWordIndex(index)
    handleSelectionChange()

    // Add a delay to position the popup properly
    wordSelectRef.current = setTimeout(() => {
      selection.removeAllRanges()
      selection.addRange(savedRange)
      wordSelectRef.current = null
      clickCountRef.current = 0
    }, 300)
  }

  function refreshOutputStructure() {
    if (parentRef.current.textContent.length && outputValue !== parentRef.current.innerText) {
      setOutputValue(parentRef.current.innerText)
      refreshOutput(new Date())
    }
  }

  const handleUndo = () => {
    if (!parentRef.current || !stateHistory?.length) return

    const toSave = stateHistory[stateHistory.length - 1]
    setStateHistory((prev) => {
      prev.pop()
      return prev
    })

    setOutputValue(toSave)
    refreshOutput(new Date())
  }

  const handleUpdateHistory = (newState) => {
    setStateHistory((prev) => {
      if (prev.length < 10) return [...prev, newState]
      return [...prev.slice(-9), newState]
    })
  }

  return (
    <div
      className={classNames(
        'flex h-full min-w-0 flex-1 flex-col justify-between text-[13px]',
        showHistory && width < 768 && 'hidden'
      )}
    >
      <TranslatorSettings
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        state={state}
        dispatch={dispatch}
        handleFlipLang={flipLanguages}
      />
      <div
        className={classNames(
          'grid grid-cols-1 gap-8 px-4 text-[15px] lg:grid-cols-2 lg:grid-rows-1',
          width < 1400 && showHistory && '!grid-cols-1 !grid-rows-2',
          {
            'grid-rows-1': folded,
            'grid-rows-2': !folded,
          },
          showHistory
            ? width < 1420
              ? 'h-[calc(100%-15rem)]'
              : 'h-[calc(100%-10rem)]'
            : width < 1280
              ? 'h-[calc(100%-15rem)]'
              : 'h-[calc(100%-10rem)]'
        )}
      >
        <div
          className={classNames('relative h-full w-full', {
            hidden: folded,
          })}
        >
          <textarea
            id='input'
            spellCheck={false}
            className={classNames(
              'border-brand-grey bg-brand-grey-bg h-full resize-none rounded-xl border-solid pb-10 text-[15px] outline-none focus-visible:outline-none',
              {
                'w-full border p-2': !folded,
                'w-0': folded,
                'rtl-text': changeInputWritingDirection,
              }
            )}
            value={state.inputValue}
            onChange={(event) => dispatch({ type: 'SET_INPUT', payload: event.target.value })}
            data-tooltip-id='translator-textInput'
            dir={changeInputWritingDirection ? 'rtl' : 'ltr'}
          />
          <div className='absolute bottom-2 left-3 flex gap-2'>
            <div
              onClick={handleChangeRecording}
              className='bg-brand-grey-bg relative cursor-pointer rounded-full p-[6px] hover:bg-slate-100'
            >
              {isTranscriptionLoading ? (
                <CircleNotch
                  weight='bold'
                  color='#000000'
                  size={20}
                  className='pointer-events-none m-[2px] animate-spin cursor-default'
                />
              ) : isRecording ? (
                <>
                  <span className='bg-brand-violet absolute left-[25%] top-[25%] inline-flex h-1/2 w-1/2 animate-[ping_3s_cubic-bezier(0,1.34,1,1.8)_infinite] rounded-full opacity-75' />
                  <Stop />
                </>
              ) : (
                <MicNone />
              )}
            </div>
            <div
              className={classNames(
                'bg-brand-grey-bg cursor-pointer rounded-full hover:bg-slate-100',
                !state.inputValue.length &&
                  audioStream.name !== 'input' &&
                  'pointer-events-none opacity-70'
              )}
              onClick={() => {
                if (audioStream.name === 'input') return setAudioStream({ name: '', data: null })
                if (!isRecording) handleTTS(state.inputValue, 'input')
              }}
            >
              {isTTSLoading.name === 'input' ? (
                <CircleNotch
                  weight='bold'
                  color='#000000'
                  size={20}
                  className='pointer-events-none m-[8px] animate-spin cursor-default'
                />
              ) : audioStream.name === 'input' ? (
                <Stop className='m-[6px]' />
              ) : (
                <VolumeUpOutlined className='m-[6px]' />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames('group relative h-full w-full', {
            'col-span-2': folded,
          })}
        >
          {!(width < 1400 && showHistory) && (
            <div
              className={classNames(
                'fold absolute left-1/2 flex h-[50px] w-[16px] -translate-x-2/3 rotate-90 cursor-pointer items-center justify-center rounded-[4px]  lg:top-[50%] lg:-translate-x-full lg:-translate-y-1/2 lg:rotate-0',

                {
                  'bg-brand-green -top-6 -translate-y-1/2 lg:-left-4': !folded,
                  '-translate-y-2/3 bg-[#36363680] lg:left-0': folded,
                }
              )}
              onClick={() => setFolded((prev) => !prev)}
            >
              {folded ? (
                <ChevronRight className='text-white' />
              ) : (
                <ChevronLeft className='text-white' />
              )}
            </div>
          )}
          <Selection.Root
            onOpenChange={(open) => {
              if (!open) setSelectedWordIndex()
            }}
          >
            <Selection.Trigger
              className='-ml-8 -mt-5 pl-8 pt-5'
              style={{ height: 'calc(100% + 1.25rem)' }}
            >
              <pre
                className={classNames(
                  'border-brand-grey bg-brand-grey-bg h-full w-full whitespace-pre-wrap rounded-xl border-[1px] border-solid focus-visible:outline-none',
                  {
                    'rtl-text': changeOutputWritingDirection,
                  }
                )}
                style={{ font: 'inherit' }}
                onMouseUp={() => handleSelectionChange()}
                data-tooltip-id='translator-output'
                dir={changeOutputWritingDirection ? 'rtl' : 'ltr'}
              >
                {/* TTS button */}
                <div
                  className={classNames(
                    'bg-brand-grey-bg absolute bottom-2 left-3 cursor-pointer rounded-full hover:bg-slate-100',
                    !parentRef?.current?.textContent.length &&
                      audioStream.name !== 'output' &&
                      'pointer-events-none opacity-70'
                  )}
                  onClick={() => {
                    if (audioStream.name === 'output')
                      return setAudioStream({ name: '', data: null })
                    if (!isRecording) handleTTS(parentRef?.current?.textContent, 'output')
                  }}
                >
                  {isTTSLoading.name === 'output' ? (
                    <CircleNotch
                      weight='bold'
                      color='#000000'
                      size={20}
                      className='pointer-events-none m-[8px] animate-spin cursor-default'
                    />
                  ) : audioStream.name === 'output' ? (
                    <Stop className='m-[6px]' />
                  ) : (
                    <VolumeUpOutlined className='m-[6px]' />
                  )}
                </div>

                {/* Copy button */}
                <div
                  className='bg-brand-grey-bg absolute bottom-2 right-3 cursor-pointer rounded-full hover:bg-slate-100'
                  onClick={() =>
                    CopyToClipBoard(
                      parentRef?.current?.textContent,
                      <T keyName='eleo-text-copied-success-message'>
                        Text successfully copied on your clipboard
                      </T>
                    )
                  }
                >
                  <ContentCopy className='m-[8px]' fontSize='small' />
                </div>

                {/* Output content */}
                <div
                  id='context'
                  ref={parentRef}
                  spellCheck={false}
                  onInput={() => {
                    dispatchAction(
                      setTranslatorGeneratedResultHistory(parentRef?.current?.innerText)
                    )
                    handleUpdateHistory(contentRef.current)
                  }}
                  onKeyDown={(e) => {
                    // console.log(parentRef.current?.innerText)
                    contentRef.current = parentRef.current?.innerText

                    if (e.key !== 'Escape') {
                      // Dispatch 'Escape' to close context menu
                      parentRef.current?.dispatchEvent(
                        new KeyboardEvent('keydown', {
                          key: 'Escape',
                          code: 'Escape',
                          bubbles: true,
                          cancelable: true,
                        })
                      )
                    }
                    if (e.ctrlKey && e.key === 'z') {
                      e.preventDefault()
                      handleUndo()
                    }
                  }}
                  onBlur={refreshOutputStructure}
                  contentEditable='plaintext-only'
                  suppressContentEditableWarning
                  className='h-full overflow-y-auto rounded-[10px] p-2 pb-10 text-[15px] focus-visible:outline-none'
                  key={outputKey}
                >
                  {outputValue.split(/(\s+|\n)/).map((word, index) => {
                    if (/(\s+|\n)/.test(word)) {
                      return word
                    } else {
                      if (!word.length) return null
                      return (
                        <span
                          key={index}
                          onClick={(e) => handleSelectWord(e, index)}
                          className={classNames(
                            'hover:bg-brand-violet/20',
                            index === selectedWordIndex && '!bg-brand-violet py-[2px] text-white'
                          )}
                        >
                          {word}
                        </span>
                      )
                    }
                  })}
                </div>
              </pre>
            </Selection.Trigger>

            <Selection.Portal>
              <Selection.Content
                side='bottom'
                align='start'
                collisionPadding={{ bottom: 50 }}
                className='z-[9999]'
              >
                <ContextMenu
                  selectedText={selectedText}
                  handleChangeSelectedText={handleChangeSelectedText}
                  deleteSelection={handleDeleteSelectedText}
                  getTextContext={getTextContext}
                />
              </Selection.Content>
            </Selection.Portal>
          </Selection.Root>

          {/* Copy Button */}
        </div>
      </div>

      <div className='mt-10 grid grid-cols-1 gap-x-8 px-4 lg:mt-auto lg:grid-cols-2'>
        <div className='mb-[16px] flex items-end justify-center gap-[10px]'>
          <Button
            color='gray'
            className='h-[45px]'
            onClick={resetFields}
            data-tooltip-id='translator-clear'
          >
            <p className='text-[15px] font-medium uppercase leading-none'>
              <T keyName='eleo-history-action-clear'>clear</T>
            </p>
          </Button>
          <Button
            className='h-[45px] w-full'
            color='dark'
            onClick={() => handleSubmit()}
            disabled={loading.name === 'translate' && loading.status}
            data-tooltip-id='translator-translate'
          >
            {loading.name === 'translate' && loading.status ? (
              <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
            ) : (
              <p className='text-[15px] font-medium uppercase leading-none'>
                <T keyName='eleo-tool-translator-translate-action'>translate</T>
              </p>
            )}
          </Button>
        </div>
        <div className='mb-[16px] flex items-end justify-end gap-[10px]'>
          <Button
            color='gray'
            className={classNames('h-[45px] px-[18px]')}
            onClick={() =>
              CopyToClipBoard(
                parentRef?.current?.textContent,
                <T keyName='eleo-text-copied-success-message'>
                  Text successfully copied on your clipboard
                </T>
              )
            }
            data-tooltip-id='translator-copy'
          >
            <ContentCopy className='h-4 w-4 ' style={{ color: '#363636', fontSize: 24 }} />
          </Button>
          <Button
            color='gray'
            className='h-[45px] px-[18px]'
            onClick={() => showShareModal(parentRef?.current?.textContent)}
            data-tooltip-id='translator-share'
          >
            <Share weight='fill' color='#363636' size={24} />
          </Button>
          {/* Continue in chat button */}
          {Boolean(
            parentRef.current?.textContent?.trim().length && state.inputValue?.trim().length
          ) &&
            !loading.status && (
              <Link
                id='continue-in-chat'
                className='flex w-full items-center md:w-auto'
                to={'/chat'}
                state={{
                  history: [
                    {
                      role: 'user',
                      content: `Translate ${state.inputLanguage !== 'auto' ? 'from ' + state.inputLanguage + ' ' : ''}to ${state.outputLanguage}:\n${state.inputValue}`,
                    },
                    { role: 'assistant', content: parentRef.current?.textContent },
                  ],
                }}
              >
                <Button color='gray' className='h-[45px] w-full px-[18px]'>
                  <div className='flex items-center gap-[6px]'>
                    <p className='text-[15px] font-medium uppercase leading-none'>
                      <T keyName='eleo-continue-in-chat'>continue in chat</T>
                    </p>
                  </div>
                </Button>
              </Link>
            )}
          {loading.status ? (
            <Button
              color='gray'
              className='h-[45px] w-full px-[18px] md:w-auto'
              onClick={() => handleStopRequest()}
            >
              <div className='flex items-center gap-[6px]'>
                <p className='text-[15px] font-medium uppercase leading-none'>
                  <T keyName='eleo-result-stop-generating'>stop generating</T>
                </p>
                <Refresh
                  weight='light'
                  style={{ color: '#363636', fontSize: 24 }}
                  className={classNames({
                    'animate-spin': loading.name,
                  })}
                />
              </div>
            </Button>
          ) : (
            <Button
              color='gray'
              className='h-[45px] px-[18px]'
              onClick={() => handleSubmit('regenerate')}
              data-tooltip-id='translator-regenerate'
            >
              <div className='flex items-center gap-[6px]'>
                <p className='text-[15px] font-medium uppercase leading-none'>
                  <T keyName='eleo-result-regenerate'>regenerate</T>
                </p>
                <Refresh weight='light' style={{ color: '#363636', fontSize: 24 }} />
              </div>
            </Button>
          )}
        </div>
      </div>

      <AudioPlayer
        streamSource={audioStream.data}
        setSource={(data) => setAudioStream({ name: '', data: data })}
      />
    </div>
  )
}
