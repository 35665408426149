import { useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'
import { ChatBubbleOutline, Favorite, FavoriteBorder, Info } from '@mui/icons-material'
import CategoryPill from './CategoryPill'

export default function LibraryItem({
  author,
  name,
  description,
  image,
  href,
  infoTooltip,
  category,
  id,
  handleLikeChatbot,
  isLiked,
}) {
  const navigate = useNavigate()
  const { t } = useTranslate()

  return (
    <div className='relative flex  min-w-0 cursor-pointer flex-col justify-between rounded-[10px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] transition hover:scale-[1.03] md:h-[230px] md:w-[210px] md:rounded-[20px] xl:h-[250px] xl:w-[230px]'>
      <div
        onClick={() => navigate(href)}
        className='w-[calc(100%-76px)] flex-1 p-4 md:w-auto md:p-5 md:text-center xl:p-[30px]'
      >
        <div className='mb-2 flex items-start gap-[6px] text-[15px] font-semibold leading-[1.2em] md:block md:text-[16px] xl:text-[18px]'>
          {image && <img src={image} alt='' className='mx-auto size-[80px]' />}
          <h2>{name}</h2>

          {infoTooltip && (
            <div
              onClick={(e) => e.stopPropagation()}
              data-tooltip-id={`eleo-tooltip`}
              data-tooltip-keyname={infoTooltip}
              className='-m-3 p-3 md:absolute md:bottom-4 md:right-4 md:-m-2 md:p-2'
            >
              <Info fontSize='small' className='text-brand-charcoal opacity-20 ' />
            </div>
          )}
        </div>
        <div className='text-balance text-[12px] leading-[1.5em] md:text-[14px] xl:text-[15px]'>
          {description}
        </div>
        <div className='text-balance text-[12px]'>{author}</div>
        <CategoryPill category={t(category)} />
      </div>
      <div className='flex h-10 border-t'>
        <div
          onClick={() => navigate(href)}
          className='flex flex-1 items-center justify-center gap-1 border-r'
        >
          <ChatBubbleOutline fontSize='inherit' />
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-start-talking', 'Start talking')}
          </span>
        </div>
        <div
          onClick={() => handleLikeChatbot(id)}
          className='flex flex-1 items-center justify-center gap-1'
        >
          {isLiked ? <Favorite fontSize='inherit' /> : <FavoriteBorder fontSize='inherit' />}
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-like', 'Like it')}
          </span>
        </div>
      </div>
    </div>
  )
}
