/***
 *
 *   useVoices hook
 *   fetch and return available voices for tts
 *
 **********/

import { useState, useEffect } from 'react'
import { useAPI } from 'components/lib'

export function useVoices() {
  const [state, setState] = useState({ data: null, loading: false })
  const voices = useAPI('/api/utility/voices')

  useEffect(() => {
    setState({ loading: true })

    if (voices.data) {
      setState({
        data: {
          voices: voices?.data?.map((voice) => {
            return {
              value: voice.Id,
              label: `${voice.Id}, ${voice.Gender}, ${voice.LanguageName}`,
              raw: voice,
            }
          }),
        },
        loading: false,
      })
    }
  }, [voices])

  return state
}
