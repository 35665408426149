import { useEffect, useRef } from 'react'
import { Howl } from 'howler'

const AudioPlayer = ({ streamSource, setSource, callback }) => {
  const mediaSourceRef = useRef(getMediaSource())
  var audioRef = useRef(
    new Howl({
      src: [URL.createObjectURL(mediaSourceRef.current)],
      ext: ['mp3'],
      autoplay: true,
      html5: true,
      onplayerror: function () {
        audioRef.current?.once('unlock', function () {
          audioRef.current?.play()
        })
      },
      format: 'mp3',
    })
  )
  useEffect(() => {
    if (!streamSource) return
    const mediaSource = mediaSourceRef.current
    const audio = audioRef.current

    audio.unload()
    audio._src = [URL.createObjectURL(mediaSourceRef.current)]
    audio.load()

    const sourceOpenHandler = async () => {
      try {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg')
        const reader = streamSource.getReader()

        const processStream = ({ done, value }) => {
          if (done) {
            if (sourceBuffer.updating) return setTimeout(() => processStream({ done, value }), 100) // Retry ending shortly
            if (mediaSource.readyState === 'ended') return
            return mediaSource.endOfStream()
          }

          if (!sourceBuffer.updating) {
            sourceBuffer.appendBuffer(value)
          } else {
            // Retry appending shortly
            return setTimeout(() => processStream({ done, value }), 100)
          }

          reader.read().then(processStream)
        }

        reader.read().then(processStream)
      } catch (error) {
        console.error('Error handling MediaSource', error)
      }
    }

    const handlePlaybackEnd = () => {
      setSource(null)
      callback?.()
    }

    mediaSource.addEventListener('sourceopen', sourceOpenHandler)
    audio.once('end', handlePlaybackEnd)
    audio.play()

    return () => {
      mediaSource.removeEventListener('sourceopen', sourceOpenHandler)
      audio.off()
      audio.stop()
      URL.revokeObjectURL(audio._src)
    }
  }, [streamSource, setSource, callback])

  return
}

function getMediaSource() {
  if (window.ManagedMediaSource) {
    return new window.ManagedMediaSource()
  }
  if (window.MediaSource) {
    return new window.MediaSource()
  }

  throw new Error('No MediaSource API available')
}

export default AudioPlayer
