import { Close } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { SocialShare, ViewContext } from 'components/lib'
import { errorToast } from 'helpers'
import { LANDING_PAGE_URL } from 'helpers/image'
import { Sparkle } from 'phosphor-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Microbutton } from 'ui'
import ImageDisplay from './ImageDisplay'

export default function ImagesOutput({
  images,
  imageData,
  setIsLoading,
  requestGeneration,
  currentIndex,
  isGenerating,
  history,
  queueSize,
  previewData,
  handleAbort,
  setImagePreviewData,
  imagePreviewData,
}) {
  const context = useContext(ViewContext)
  const { width } = useWindowDimensions()
  const { t } = useTranslate()
  const outputAreaRef = useRef()
  const [outputAreaSize, setOutputAreaSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setOutputAreaSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        })
      }
    })

    if (outputAreaRef.current) {
      resizeObserver.observe(outputAreaRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [outputAreaRef])

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(`Generated by Eleo.ai`)}
              url={`${LANDING_PAGE_URL}${content}`}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  function preview(imageUrl, isMobile = false) {
    setImagePreviewData({ isVisible: true, target: imageUrl, isMobile: isMobile })
  }

  async function moreLikeThis(imageIndex) {
    setIsLoading(true)

    try {
      const data = {
        ...imageData,
        image: images[imageIndex],
        samples: 3,
        morphStrength: 0.5,
        moreLikeThis: true,
        action: 'modify',
        tool: 'modify',
      }
      return await requestGeneration(data)
    } catch (err) {
      if (!err.response.data.message)
        errorToast(t('eleo-generate-image-error', 'Failed to generate your image.'))
      context.handleError(err)
      setIsLoading(false)
    }
  }

  const getPreviewLayout = (samples) => {
    const padding = 16
    const proportion = previewData.proportions?.split('/') ?? [1, 1]
    let { width, height } = outputAreaSize
    width = width - 2 * padding
    height = height - 2 * padding

    const layouts = []
    if (samples === 1) {
      layouts.push({ rows: 1, cols: 1 })
    } else if (samples === 2) {
      layouts.push({ rows: 1, cols: 2 })
      layouts.push({ rows: 2, cols: 1 })
    } else if (samples === 3) {
      layouts.push({ rows: 1, cols: 3 })
      layouts.push({ rows: 3, cols: 1 })
      layouts.push({ rows: 2, cols: 2 })
    } else if (samples === 4) {
      layouts.push({ rows: 1, cols: 4 })
      layouts.push({ rows: 2, cols: 2 })
      layouts.push({ rows: 4, cols: 1 })
    }

    let bestLayout = null
    let bestFit = Infinity

    layouts.forEach((layout) => {
      const cellWidth = width / layout.cols
      const cellHeight = height / layout.rows

      const rectWidthInCellHeight = (cellHeight * proportion[0]) / proportion[1]
      const rectHeightInCellWidth = (cellWidth * proportion[1]) / proportion[0]

      const widthDiff = Math.abs(cellWidth - rectWidthInCellHeight)
      const heightDiff = Math.abs(cellHeight - rectHeightInCellWidth)

      const totalDiff =
        samples === layout.rows * layout.cols
          ? (widthDiff + heightDiff) * layout.rows * layout.cols
          : widthDiff * widthDiff + heightDiff * heightDiff

      if (totalDiff < bestFit) {
        bestFit = totalDiff
        bestLayout = layout
      }
    })

    return bestLayout
  }
  const previewLayout = getPreviewLayout(previewData.samples)

  return (
    <>
      <div ref={outputAreaRef} className='flex flex-1 flex-col'>
        <div
          key={currentIndex}
          id='context-menu'
          className={classNames(
            'relative grid min-w-0 gap-[10px] p-[10px] lg:gap-5 lg:p-4',
            previewLayout?.rows === 4 ? 'mx-auto h-full' : 'my-auto',
            previewLayout?.rows === 2 && previewLayout?.cols === 1 && 'h-full [&>*]:mx-auto',
            previewLayout?.rows === 1 &&
              (previewLayout?.cols === 1
                ? 'h-full [&>*:first-child]:mx-auto'
                : '[&>*:first-child]:ml-auto'),
            previewLayout?.cols === 3 && '[&>*:nth-child(2)]:mx-auto',
            previewLayout?.rows === 2 && '[&>*:nth-child(odd)]:ml-auto',
            previewLayout?.rows === 3 && 'h-full [&>*]:mx-auto'
          )}
          style={{
            maxHeight: 'calc(100dvh - 132px)',
            gridTemplateRows: `repeat(${previewLayout?.rows}, minmax(0, 1fr))`,
            gridTemplateColumns: `repeat(${Math.ceil(previewData.samples / previewLayout?.rows)}, minmax(0, 1fr))`,
          }}
          data-tooltip-id='images-imageCreator-imageArea'
          data-tooltip-place={width > 1023 ? 'left' : 'top'}
        >
          {Array.from({ length: previewData.samples }, (_, index) => index).map((index) => (
            <ImageDisplay
              key={index}
              image={images?.[index]}
              imageData={imageData}
              preview={preview}
              showShareModal={showShareModal}
              moreLikeThis={() => moreLikeThis(index)}
              name={`Generation-${index + 1}`}
              useCors
              previewProportions={previewData.proportions}
              imagePreviewData={imagePreviewData}
              setImagePreviewData={setImagePreviewData}
            />
          ))}

          {isGenerating && currentIndex === history.length - 1 && (
            <div className='absolute left-1/2 top-1/2 w-[200px] -translate-x-1/2 -translate-y-1/2 space-y-6'>
              <div className='flex flex-col items-center gap-[10px]'>
                <Sparkle fontSize={28} weight='fill' className='text-brand-violet' />
                {isGenerating && queueSize > 1 ? (
                  <div className='space-y-[6px] text-center'>
                    <div className='text-brand-violet button-main text-center uppercase'>
                      <T
                        keyName='eleo-image-output-title-queue'
                        params={{ br: <br /> }}
                        defaultValue='Generation will<br></br>start soon'
                      />
                    </div>
                    <div className='text-brand-violet body-small'>
                      {t('eleo-images-queue-position', 'Your position in queue: ') + queueSize}
                    </div>
                  </div>
                ) : (
                  <div className='text-brand-violet button-main text-center uppercase'>
                    <T
                      keyName='eleo-image-output-title'
                      params={{ br: <br /> }}
                      defaultValue=' generating<br></br>images'
                    />
                  </div>
                )}
                <Microbutton
                  variant='solid'
                  iconPlacement='left'
                  icon={<Close fontSize='inherit' />}
                  text={t('eleo-modal-cancel-button')}
                  className='uppercase'
                  onClick={handleAbort}
                />

                {/* <div className='flex flex-col gap-2 rounded-[4px] bg-[#C6C6E4] p-4'>
                  <div className='flex items-center justify-between'>
                    <div className='body-small text-brand-violet font-semibold uppercase'>
                      {t('eleo-tip', 'tip')}
                    </div>
                    <div className='text-brand-violet text-[18px]'>
                      <Lightbulb fontSize='inherit' />
                    </div>
                  </div>

                  <div className='body-small text-brand-violet'>{getRandomTip()}</div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
