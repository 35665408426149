import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useStream } from 'components/hooks/stream'
import { Loader, ViewContext } from 'components/lib'
import { errorToast, getLandingUrl, transformDashToSpace } from 'helpers'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import defaultAvatar from '../../images/eleo_raw.svg'
import ChatBotHistory from './chatBotHistory'
import ChatBotUi from './chatBotUi'
import ChatNotActive from './chatNotActive'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import eleoLogo from '../../images/sygnet.svg'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { chatbotLibrary, setLibraryChatbotData } from 'store/toolInputHistoriesHolder/chatbot'

export const ChatBotGeneralLayout = ({
  chatBotName,
  setChatIconSizes,
  setIsVisible,
  widget = false,
  visible = true,
  url = '/api/chat-bot/conversation',
  showUserHistory = false,
  showHeader = true,
}) => {
  const { handleError } = useContext(ViewContext)
  const params = useParams()
  const chatInputHistoryValues = useSelector(chatbotLibrary)
  const dispatchAction = useDispatch()
  const storeFormData = chatInputHistoryValues[params.botName]?.form
  const storeHistoryData = chatInputHistoryValues[params.botName]?.history
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream(false) // TODO add abort logic
  const [chatBotSettings, setChatBotSettings] = useState('')
  const [settingsLoading, setSettingsLoading] = useState('')
  const [message, setMessage] = useState(storeFormData?.inputValue ?? '')
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')))
  const [chatBotHistory, setChatBotHistory] = useState([])
  const [historyIsVisible, setHistoryIsVisible] = useState(storeHistoryData?.showHistory ?? false)
  const [historyIsLoading, setHistoryIsLoading] = useState(false)
  const [chatId, setChatId] = useState(storeHistoryData?.chatId ?? null)
  const [messages, setMessages] = useState(storeHistoryData?.chatLog ?? [])
  const [language, setLanguage] = useState(storeFormData?.language ?? 'auto')
  const [isSearchWeb, setSearchWeb] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslate()
  const [audioStream, setAudioStream] = useState()

  const [isAutoAudio, setIsAutoAudio] = useState(storeFormData?.isAutoAudio ?? false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [ttsIndex, setTtsIndex] = useState()

  useEffect(() => {
    if (!widget && chatBotSettings && chatBotSettings.isPublic) {
      dispatchAction(
        setLibraryChatbotData({
          chatbotId: params.botName,
          data: {
            history: {
              chatLog: messages,
              activeHistory: [],
              chatId: chatId,
              showHistory: historyIsVisible,
            },
            form: {
              inputValue: message,
              language: language,
              isAutoAudio: isAutoAudio,
            },
          },
        })
      )
    }
  }, [
    chatBotSettings,
    chatId,
    messages,
    historyIsVisible,
    message,
    language,
    isAutoAudio,
    dispatchAction,
    params.botName,
    widget,
  ])

  //show history only if user is creator of chat bot
  const userCanAccessHistory =
    showUserHistory || userInfo?.account_id === chatBotSettings?.account_id

  useEffect(() => {
    const updatePopularity = async () => {
      try {
        axios.post(`/api/chat-bot/library/stats/${chatBotSettings.id}`)
      } catch (err) {
        handleError(err)
      }
    }

    if (chatBotSettings && chatBotSettings.id && chatBotSettings.isPublic && !widget)
      updatePopularity()
  }, [chatBotSettings, handleError, widget])

  const handleChat = useCallback(
    async (e, question, newChat = false) => {
      e?.preventDefault()

      if (!message?.length && !question?.length) {
        return
      }

      let newChatLog
      if (question && newChat) newChatLog = [{ role: 'user', content: question }]
      else newChatLog = [...messages, { role: 'user', content: question ?? message }]

      setMessage('')
      setMessages(newChatLog)

      const data = {
        language: language ?? chatBotSettings.language,
        isGuruEnabled: chatBotSettings.isGuruEnabled, // deprecated
        model: chatBotSettings.modelId,
        name: transformDashToSpace(chatBotSettings.name),
        monthlyLimit: chatBotSettings.monthlyLimit,
        responseLength: chatBotSettings.responseLength,
        storyId: chatBotSettings.storyId,
        tone: chatBotSettings.tone,
        account_id: chatBotSettings.account_id,
        id: chatBotSettings.id,
        prompt: question ?? message,
        messages: newChatLog,
        isSearchWeb: isSearchWeb,
        kwargs: {
          stream: true,
        },
        chatId,
      }
      try {
        setIsGenerating(true)
        setMessages((prevChatLog) => [
          ...prevChatLog,
          { role: 'assistant', content: 'STATE_LOADING' },
        ])
        const completion = await fetchCompletion(url, data)

        // If completion hasn't started and the user aborted, remove the placeholder
        if (!completion?.length)
          return setMessages((prev) => prev.filter((_, index) => index !== prev.length - 1))

        const pages = Math.max(1, Math.ceil(messages.length / 10))
        if (userCanAccessHistory) setTimeout(() => getChatBotHistory(pages), 500)

        if (isAutoAudio) handleTTS(completion, messages.length + 1)
      } catch (err) {
        const limitErrorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-limit-error-primary',
            "Oops! It looks like I've run out of words for the moment... Try asking me later."
          ),
        }
        const errorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-error-primary',
            "I'm not feeling quite well... Try asking me later."
          ),
        }

        if (err.name === 'LimitError') {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = limitErrorMessage
            return newChatLog
          })
        } else {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = errorMessage
            return newChatLog
          })
        }
      } finally {
        setIsGenerating(false)
      }
    },
    [chatBotSettings, chatId, fetchCompletion, language, message, messages, t]
  )

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleChat()
    }
  }

  const getChatBotHistory = useCallback(
    async (pages = 1) => {
      try {
        setHistoryIsLoading(true)
        const response = await axios.get(`/api/chat-bot/histories?pages=${pages}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: {
            chatBotId: chatBotSettings.id,
          },
        })
        const data = response.data
        const reversedChatHistoryItems = data.map((historyItem) => {
          return {
            ...historyItem,
            history: historyItem.history.reverse(),
          }
        })

        const historyData = reversedChatHistoryItems.map((item) => ({
          id: item.chatId,
          question: item.question,
          answer: item.answer,
          date_created: item.date_created,
          words: item.wordLength,
          history: item.history,
        }))

        setChatBotHistory(historyData)
      } catch (err) {
        console.error(err)
      } finally {
        setHistoryIsLoading(false)
      }
    },
    [userInfo?.token, chatBotSettings.id]
  )

  const handleTTS = async (text, index) => {
    setAudioStream()
    if (index === ttsIndex) {
      setTtsIndex()
      return
    }

    setTtsIndex(index)
    const data = {
      input: text,
    }
    if (chatBotSettings.voice) data.voiceId = chatBotSettings.voice

    const res = await fetch(axios.defaults.baseURL + '/api/chat-bot/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  useEffect(() => {
    if (!completionState.message) return
    setMessages((prevChatLog) => {
      const newChatLog = [...prevChatLog]
      newChatLog[newChatLog.length - 1] = {
        role: 'assistant',
        content: completionState.message,
      }
      return newChatLog
    })
  }, [completionState.message])

  useEffect(() => {
    const cmd = completionState.command
    if (cmd?.chatId) setChatId(cmd.chatId)
  }, [completionState.command])

  useEffect(() => {
    const getChatBotInfo = async () => {
      setSettingsLoading(true)
      try {
        const response = await axios.get(`/api/chat-bot/get/${params.botName || chatBotName}`)
        const chatBot = response.data
        setChatBotSettings(chatBot)
      } catch (err) {
        console.error(err)
      } finally {
        setSettingsLoading(false)
      }
    }

    getChatBotInfo()
  }, [chatBotName, params.botName])

  // Load form data
  useEffect(() => {
    if (!chatBotSettings) return

    const message = searchParams.get('question')
    if (message) {
      handleChat(null, message, true)
      setSearchParams('', { replace: true })
    }
  }, [searchParams, setSearchParams, chatBotSettings, handleChat])

  // useEffect(() => {
  //   setLanguage(chatBotSettings.language)
  // }, [chatBotSettings])

  useEffect(() => {
    if (chatBotSettings && userCanAccessHistory) {
      getChatBotHistory(1)
    }
  }, [chatBotSettings, getChatBotHistory, userCanAccessHistory])

  if (settingsLoading) return <Loader />

  return (
    <div className='flex h-full flex-col'>
      {!chatBotSettings?.settings?.activeOnEleo && !widget ? (
        <ChatNotActive />
      ) : (
        <>
          {!widget && showHeader && (
            <nav className={classNames('z-[90] w-full bg-[#ffffff]  py-4 shadow-lg')}>
              <div
                className={classNames(
                  'flex h-[3.75rem] w-36 shrink-0 items-center justify-center lg:w-[12.5rem]'
                )}
              >
                <a href={getLandingUrl()}>
                  <img className='h-6 w-auto' src={eleoLogo} alt='Company Logo' />
                </a>
              </div>
            </nav>
          )}
          <div
            className={classNames('flex min-h-0 flex-1', {
              hidden: !visible,
            })}
          >
            {historyIsVisible && (
              <ChatBotHistory
                chatBotHistoryData={chatBotHistory}
                historyIsLoading={historyIsLoading}
                setMessages={setMessages}
                intro={chatBotSettings?.intro}
                chatBotId={chatBotSettings?.id}
                chatId={chatId}
                setChatId={setChatId}
                setLanguage={setLanguage}
                widget={widget}
                language={language}
                userCanAccessHistory={userCanAccessHistory}
                setHistoryIsVisible={setHistoryIsVisible}
                setChatIsActive={setIsVisible}
                setShowHistory={setHistoryIsVisible}
                setChatBotHistoryData={setChatBotHistory}
                setAudioStream={setAudioStream}
              />
            )}

            {widget && (
              <ShrinkedChat
                setChatIsActive={setIsVisible}
                chatIcon={chatBotSettings?.settings?.chatIcon}
                introText={chatBotSettings?.intro}
                setChatIconSizes={setChatIconSizes}
                visible={visible}
              />
            )}

            <ChatBotUi
              chatBotSettings={chatBotSettings}
              setMessage={setMessage}
              handleKeyDown={handleKeyDown}
              message={message}
              messages={messages}
              setMessages={setMessages}
              handleChat={handleChat}
              setChatId={setChatId}
              setLanguage={setLanguage}
              widget={widget}
              language={language}
              userCanAccessHistory={userCanAccessHistory}
              setHistoryIsVisible={setHistoryIsVisible}
              setChatIsActive={setIsVisible}
              handleTTS={handleTTS}
              isAutoAudio={isAutoAudio}
              setIsAutoAudio={setIsAutoAudio}
              setAudioStream={setAudioStream}
              avatar={userInfo?.avatar}
              isGenerating={isGenerating}
              handleStopRequest={handleStopRequest}
              audioStream={audioStream}
              ttsIndex={ttsIndex}
              setTtsIndex={setTtsIndex}
              isSearchWeb={isSearchWeb}
              setSearchWeb={setSearchWeb}
            />
          </div>

          {widget && (
            <ShrinkedChat
              setChatIsActive={setIsVisible}
              chatIcon={chatBotSettings?.settings?.chatIcon}
              introText={chatBotSettings?.intro}
              setChatIconSizes={setChatIconSizes}
              visible={visible}
            />
          )}

          <AudioPlayer
            streamSource={audioStream}
            setSource={setAudioStream}
            callback={setTtsIndex}
          />
        </>
      )}
    </div>
  )
}

function ShrinkedChat({ setChatIsActive, chatIcon, introText, visible, setChatIconSizes }) {
  const chatIconRef = useRef(null)
  return (
    <div
      className={classNames({
        'fixed bottom-0 z-[9999999] overflow-hidden': !visible,
        hidden: visible,
      })}
    >
      {chatIcon ? (
        <div
          className='cursor-pointer'
          onClick={() => setChatIsActive(true)}
          data-tooltip-id={`chat-tooltip`}
        >
          <div className='flex items-center justify-center'>
            <img
              className='h-auto max-w-full'
              src={chatIcon}
              alt='icon'
              ref={chatIconRef}
              onLoad={() =>
                setChatIconSizes({
                  width: chatIconRef.current.naturalWidth,
                  height: chatIconRef.current.naturalHeight,
                })
              }
            />
          </div>
        </div>
      ) : (
        <div
          className='cursor-pointer'
          onClick={() => setChatIsActive(true)}
          data-tooltip-id={`chat-tooltip`}
        >
          <div className='flex items-center justify-center'>
            <img className='h-auto max-w-full' src={defaultAvatar} alt='eleo logo' />
          </div>
        </div>
      )}
      <Tooltip id={`chat-tooltip`} className='!bg-brand-violet shadow-md'>
        <ul className='flex max-w-[250px] flex-col gap-[10px]'>
          <li className='flex flex-col text-[14px] leading-[1.2]'>
            <b>{introText || 'How I Can Help You?'}</b>
          </li>
        </ul>
      </Tooltip>
    </div>
  )
}
