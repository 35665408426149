import {
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  ContentCopy,
  DeleteOutline,
  ShareOutlined,
} from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useStream } from 'components/hooks/stream'
import { SocialShare, ViewContext } from 'components/lib'
import { CopyToClipBoard } from 'helpers'
import arrowsInward from 'images/icons/arrows_inward.svg'
import arrowsOutward from 'images/icons/arrows_outward.svg'
import { Sparkle } from 'phosphor-react'
import { useContext, useEffect, useState } from 'react'
import Synonyms from './synonyms'
import ContentLoader from 'react-content-loader'
// import { ResizableBox } from 'react-resizable'

export function ContextMenu({
  selectedText,
  disabled,
  handleChangeSelectedText,
  getTextContext,
  deleteSelection,
  enableEdit = true,
  tools = ['rephrase', 'shorter', 'longer'],
  forceNotSynonyms = false,
  regenerateUrl = '/api/ai/rephrase',
  noHeader = false,
}) {
  const context = useContext(ViewContext)
  const { t } = useTranslate()

  const isSynonyms = forceNotSynonyms ? false : selectedText?.trim().split(/\s+/).length === 1
  const [tool, setTool] = useState(isSynonyms ? tools[0] : tools.length > 1 ? null : tools[0])

  const [synonymOptions, setSynonymOptions] = useState([])
  const [generationHistory, setGenerationHistory] = useState({
    rephrase: [],
    shorter: [],
    longer: [],
  })
  const [currentIndex, setCurrentIndex] = useState({
    rephrase: 0,
    shorter: 0,
    longer: 0,
  })

  const rephrase = useStream()
  const shorter = useStream()
  const longer = useStream()
  const toolMap = { rephrase, shorter, longer }

  useEffect(() => {
    // Start generation if no content for this tool was generated before
    if (enableEdit && tool && selectedText?.length) {
      if (!generationHistory[tool].length && !toolMap[tool].state.isLoading) handleRegenerate(tool)
    }
  }, [tool, selectedText])

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(content)}
              url={window.location.origin}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  const handleRegenerate = (tool) => {
    if (toolMap[tool].state.isLoading) {
      toolMap[tool].abortController && toolMap[tool].abortController.abort()
    } else handleRegenerateSelectedText(tool)
  }

  async function handleRegenerateSelectedText(tool) {
    // If selected only one word for rephrase, get a list of synonyms
    if (isSynonyms) return handleGetSynonyms(selectedText)

    const url = regenerateUrl
    const data = {
      context: getTextContext(),
      target: selectedText,
      tool,
      kwargs: { stream: true, temperature: 0.6 },
    }
    try {
      const res = await toolMap[tool].fetch(url, data)
      setGenerationHistory((prev) => ({ ...prev, [tool]: [...prev[tool], res] }))
    } catch (err) {
      context.handleError(err)
    }
  }

  async function handleGetSynonyms() {
    const data = {
      input: getTextContext(),
    }

    try {
      const res = await axios.post('/api/ai/synonym', data)
      setSynonymOptions(res.data.synonyms)
    } catch (err) {
      if (err.name === 'CanceledError') return
      context.handleError(err)
      setSynonymOptions(null)
    }
  }

  return (
    <>
      <div
        className={classNames(
          (isSynonyms || !tool) && 'rounded-[4px] shadow-[1px_0_15px_#00000035]'
        )}
      >
        {!noHeader && (
          <div
            className={classNames(
              'inline-flex w-full items-center justify-between gap-2 bg-[#ffffff] p-2',
              enableEdit && 'min-w-[230px]',
              !isSynonyms && tool && 'shadow-[1px_-11px_10px_#00000035]',
              enableEdit && tool ? 'rounded-t-[4px]' : 'rounded-[4px]'
            )}
          >
            {/* Section 1 */}
            {enableEdit && (
              <>
                <div className='flex gap-1'>
                  <div
                    title={t('eleo-result-regenerate', 'Regenerate')}
                    className={classNames(
                      disabled && 'pointer-events-none opacity-50',
                      tool === tools[0]
                        ? 'bg-brand-violet/10 -mb-2 rounded-t-[4px] pb-3'
                        : 'cursor-pointer',
                      'relative flex items-center gap-1 py-1 pl-[5px] pr-[6px]'
                    )}
                    onClick={() => setTool(tools[0])}
                  >
                    <Sparkle size={14} style={{ color: '#363636' }} weight='fill' />
                    <span className='mt-[2px] text-[13px] font-bold'>ALT</span>
                  </div>

                  {tools.includes('shorter') && (
                    <div
                      title={t('eleo-result-shorten', 'Shorten')}
                      style={{ color: '#363636' }}
                      className={classNames(
                        'py-1 pl-[5px] pr-[6px]',
                        tool === tools[1]
                          ? 'bg-brand-violet/10 -mb-2 rounded-t-[4px] pb-3'
                          : 'cursor-pointer',
                        (disabled || isSynonyms) && 'pointer-events-none opacity-50'
                      )}
                      onClick={() => setTool(tools[1])}
                    >
                      <img className='h-[24px] w-[24px]' src={arrowsInward} alt='Lengthen' />
                    </div>
                  )}
                  {tools.includes('longer') && (
                    <div
                      title={t('eleo-result-lengthen', 'Extend')}
                      style={{ color: '#363636' }}
                      className={classNames(
                        'py-1 pl-[5px] pr-[6px]',
                        tool === tools[2]
                          ? 'bg-brand-violet/10 -mb-2 rounded-t-[4px] pb-3'
                          : 'cursor-pointer',
                        (disabled || isSynonyms) && 'pointer-events-none opacity-50'
                      )}
                      onClick={() => setTool(tools[2])}
                    >
                      <img className='h-[24px] w-[24px]' src={arrowsOutward} alt='Shorten' />
                    </div>
                  )}
                </div>
                <hr className='bg-brand-body h-[18px] w-[1px] opacity-10' />
              </>
            )}

            {/* Section 2 */}
            <div className='flex items-center gap-2'>
              <div className='flex items-center gap-1'>
                <div
                  title={t('eleo-result-copy', 'Copy')}
                  className={classNames(
                    disabled && 'pointer-events-none opacity-50',
                    'hover:bg-brand-violet/10 flex rounded-[4px] p-1'
                  )}
                >
                  <ContentCopy
                    style={{ color: '#363636', fontSize: 18 }}
                    className='cursor-pointer'
                    onClick={() =>
                      CopyToClipBoard(
                        selectedText,
                        <T keyName='eleo-text-copied-success-message'>
                          Text successfully copied on your clipboard
                        </T>
                      )
                    }
                  />
                </div>
                <div
                  title={t('eleo-result-share', 'Share')}
                  className={classNames(
                    disabled && 'pointer-events-none opacity-50',
                    'hover:bg-brand-violet/10 flex rounded-[4px] p-1'
                  )}
                >
                  <ShareOutlined
                    style={{ color: '#363636', fontSize: 18 }}
                    className='cursor-pointer'
                    onClick={() => showShareModal(selectedText)}
                  />
                </div>
              </div>

              {enableEdit && (
                <>
                  <hr className='bg-brand-grey h-[18px] w-[1px]' />

                  <div
                    title={t('eleo-result-delete', 'Delete')}
                    className={classNames(
                      disabled && 'pointer-events-none opacity-50',
                      'hover:bg-brand-violet/10 flex rounded-[4px] p-1'
                    )}
                  >
                    <DeleteOutline
                      style={{ color: '#363636', fontSize: 18 }}
                      className='cursor-pointer'
                      onClick={() => deleteSelection(selectedText)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {enableEdit && tool && (
          <div
            className={classNames(
              '-mt-[1px] rounded-b-[4px] bg-[#ffffff]',
              !isSynonyms && 'shadow-[1px_0_15px_#00000035]',
              noHeader && 'rounded-[4px]'
            )}
          >
            <div
              className={classNames(
                'bg-brand-violet flex flex-col rounded-b-[4px] bg-opacity-10',
                noHeader && 'rounded-[4px]'
              )}
            >
              {isSynonyms ? (
                <Synonyms
                  synonyms={synonymOptions}
                  handleChangeSelectedText={handleChangeSelectedText}
                />
              ) : (
                // <ResizableBox
                //   width={300}
                //   height={200}
                //   draggableOpts={{}}
                //   minConstraints={[262, 200]}
                //   maxConstraints={[400, 300]}
                //   onResizeStop={() => {
                //     const selection = window.getSelection()
                //     selection.removeAllRanges()
                //     selection.addRange(selectionRange)
                //   }}
                // >
                <div className='flex h-[255px] w-[300px] flex-col p-2 sm:w-[400px]'>
                  {/* Content */}
                  <div className='flex-1 overflow-y-auto p-[6px] text-[13px]'>
                    {toolMap[tool].state.isLoading ? (
                      (toolMap[tool].state.message ?? (
                        <ContentLoader
                          speed={2}
                          width={355}
                          height={60}
                          backgroundColor='#6363E510'
                          foregroundColor='#6363E530'
                        >
                          <rect x='0' y='0' width='355' height='18' />
                          <rect x='0' y='20' width='355' height='18' />
                          <rect x='0' y='40' width='141' height='18' />
                        </ContentLoader>
                      ))
                    ) : (
                      <div
                        className='hover:bg-brand-violet/10 cursor-pointer rounded-[4px]'
                        onClick={() =>
                          handleChangeSelectedText(generationHistory[tool][currentIndex[tool]])
                        }
                      >
                        {generationHistory[tool][currentIndex[tool]]}
                      </div>
                    )}
                  </div>

                  {/* Footer */}
                  <div className='flex items-center justify-end gap-2 pt-2'>
                    <div className='flex items-center gap-1'>
                      <div
                        className={classNames(
                          'text-brand-violet hover:bg-brand-violet/10 flex cursor-pointer self-center rounded-[4px] p-1',
                          currentIndex[tool] === 0 && 'pointer-events-none opacity-30'
                        )}
                        onClick={() =>
                          setCurrentIndex((prev) => ({ ...prev, [tool]: prev[tool] - 1 }))
                        }
                      >
                        <ChevronLeft fontSize='small' />
                      </div>

                      <span className='text-brand-violet text-[12px]'>
                        {currentIndex[tool] + 1}/
                        {Math.max(3, generationHistory[tool].length, currentIndex[tool] + 1)}
                      </span>
                      <div
                        className={classNames(
                          'text-brand-violet hover:bg-brand-violet/10 flex cursor-pointer rounded-[4px] p-1',
                          (toolMap[tool].state.isLoading ||
                            !generationHistory[tool][currentIndex[tool]]) &&
                            'pointer-events-none opacity-30'
                        )}
                        onClick={() => {
                          // Generate content at next index if not present
                          if (!generationHistory[tool][currentIndex[tool] + 1])
                            handleRegenerate(tool)
                          setCurrentIndex((prev) => ({ ...prev, [tool]: prev[tool] + 1 }))
                        }}
                      >
                        <ChevronRight fontSize='small' />
                      </div>
                    </div>

                    <hr className='bg-brand-text-secondary/10 h-[18px] w-[1px]' />

                    <div className='flex items-center'>
                      <div
                        className='text-brand-violet hover:bg-brand-violet/10 flex cursor-pointer rounded-[4px] p-1'
                        onClick={() => {
                          setTool()
                          setGenerationHistory({
                            rephrase: [],
                            shorter: [],
                            longer: [],
                          })
                          setCurrentIndex({
                            rephrase: 0,
                            shorter: 0,
                            longer: 0,
                          })
                        }}
                      >
                        <Close fontSize='small' />
                      </div>
                      <div
                        className={classNames(
                          'text-brand-violet hover:bg-brand-violet/10 flex cursor-pointer rounded-[4px] p-1',
                          (toolMap[tool].state.isLoading ||
                            !generationHistory[tool][currentIndex[tool]]) &&
                            'pointer-events-none opacity-30'
                        )}
                        onClick={() =>
                          handleChangeSelectedText(generationHistory[tool][currentIndex[tool]])
                        }
                      >
                        <Check fontSize='small' />
                      </div>
                    </div>
                  </div>
                </div>
                // </ResizableBox>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
