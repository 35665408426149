import { Animate } from 'components/lib'
import { ChatbotLibraryLayout } from 'toolComponents/chat-bot/library'

export const ChatbotLibrary = () => {
  return (
    <Animate>
      <ChatbotLibraryLayout />
    </Animate>
  )
}

export default ChatbotLibrary
