import { HelpTooltips } from 'components/help/helpTooltips'
import { useLanguages } from 'components/hooks/language'
import { useStream } from 'components/hooks/stream'
import { ToolLayoutPrimary, ViewContext } from 'components/lib'
import { convertCreativityToTemperature, errorToast, languages, queryToObj } from 'helpers'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  checkTextGeneratedResultHistory,
  checkTextInitialState,
  checkTextInputHistory,
  setCheckTextGeneratedResultHistory,
  setCheckTextInputHistory,
} from 'store/toolInputHistoriesHolder/checkText'
import WriterResult from '../WriterResult'
import { CheckForInputData, Creativity } from '../writer-options-data'
import CheckTextGenerator from './CheckTextGenerator'
import { T, useTranslate } from '@tolgee/react'
import Tour from 'components/tour'

const actionTypes = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_CHECK: 'SET_CHECK',
  LOAD_FROM_QUERY: 'LOAD_FROM_QUERY',
}

// Tour steps
const steps = [
  {
    target: '#my-text',
    content: (
      <T keyName='eleo-tour-check-1'>
        Enter the text to check. Paste or type the text you want to check.
      </T>
    ),
  },
  {
    target: '#what-to-check',
    content: (
      <T keyName='eleo-tour-check-2'>
        Choose what you want to check. Indicate what Eleo should evaluate, grammar, SEO, or the
        emotions the text conveys.
      </T>
    ),
  },
  {
    target: '#language',
    content: (
      <T keyName='eleo-tour-check-3'>
        Choose the language. Specify in which language Eleo should provide you with answers.
      </T>
    ),
  },
  {
    target: '#creativity',
    content: (
      <T keyName='eleo-tour-check-4'>
        Select the creativity of the response. The lower the creativity, the more Eleo sticks to
        facts and standards.
      </T>
    ),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload }
    case actionTypes.SET_CHECK:
      return { ...state, check: action.payload }
    case actionTypes.LOAD_FROM_QUERY:
      const values = action.payload
      let data = {
        language: languages.find((l) => values.language?.toLowerCase() === l.value.toLowerCase())
          ?.value,
        check: CheckForInputData.find((c) => values.check?.toLowerCase() === c.value.toLowerCase())
          ?.value,
      }

      data = Object.fromEntries(Object.entries(data).filter(([key, value]) => Boolean(value)))

      return { ...state, ...data }
    case actionTypes.RESET:
      return checkTextInitialState.history.selectOptionValues
    default:
      return state
  }
}

const CheckTextLayout = () => {
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream()
  const dispatchAction = useDispatch()
  const location = useLocation()
  const { t } = useTranslate()
  const checkTextInputHistoryValues = useSelector(checkTextInputHistory)
  const generatedTextHistoryValue = useSelector(checkTextGeneratedResultHistory)
  const [folded, setFolded] = useState(false)
  const context = useContext(ViewContext)
  const [generatedText, setGeneratedText] = useState(generatedTextHistoryValue)
  const [myText, setMyText] = useState(checkTextInputHistoryValues.myText)
  const [wordLength, setWordLength] = useState({ id: '500', checked: true })
  const [templateId, setTemplateId] = useState({ label: '', value: '' })
  const [loading, setLoading] = useState({ name: '', status: false })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [writerLengthOption, setWriterLengthOption] = useState('words')
  const [creativity, setCreativity] = useState(checkTextInputHistoryValues.creativity)
  const [state, dispatch] = useReducer(reducer, checkTextInputHistoryValues.selectOptionValues)
  const [history, setHistory] = useState([])
  const [checkedHistory, setCheckedHistory] = useState({
    radioBoxName: '',
    checked: false,
  })
  const [isGuruModeEnabled, setIsGuruModeEnabled] = useState(
    checkTextInputHistoryValues.isGuruModeEnabled
  )
  const [reloadHistoryData, setReloadHistoryData] = useState(0)
  const [timeStamp, setTimeStamp] = useState(Date.now())
  const [model, setModel] = useState(checkTextInputHistoryValues.model)

  const { defaultToolOutPutLanguage } = useLanguages()
  const optionsData = {
    myText,
    wordLength,
    writerLengthOption,
    creativity,
    isGuruModeEnabled,
    state,
  }

  // Load form data from query params
  useEffect(() => {
    const data = queryToObj(location.search)

    dispatch({
      type: 'LOAD_FROM_QUERY',
      payload: data,
    })

    if (data.text) setMyText(data.text)

    if (data.creativity)
      setCreativity(
        Creativity.find((c) => data.creativity?.toLowerCase() === c.value.toLowerCase())?.value
      )
    if (data.guru) setIsGuruModeEnabled(true)
  }, [location])

  const clearInputs = () => {
    setMyText('')
    setCreativity('Medium')
    setIsGuruModeEnabled(false)
    setWordLength({ id: '500', checked: true })
    dispatch({ type: actionTypes.RESET })
    dispatch({
      type: actionTypes[`SET_LANGUAGE`],
      payload: defaultToolOutPutLanguage.value,
    })
  }

  useEffect(() => {
    if (!completionState.message) return
    setGeneratedText(completionState.message)
  }, [completionState.message])

  useEffect(() => {
    if (!completionState.command) return
    const cmd = completionState.command
    if (cmd.wordsUsed) context.setWordsLeft((prev) => prev - cmd.wordsUsed)
    if (cmd.error) errorToast(t('eleo-error-generic'))
  }, [completionState.command])

  const handleSubmitWriterOptions = async (loaderName) => {
    setTimeStamp(Date.now())
    setGeneratedText('')
    setLoading({ name: loaderName || 'generate', status: true })

    const kwargs = {
      stream: true,
      temperature: convertCreativityToTemperature(optionsData.creativity),
    }

    const formData = new FormData()
    formData.append('model', model?.value)
    formData.append('tool', 'checkText')
    formData.append('writerOptions', JSON.stringify(optionsData))
    formData.append('kwargs', JSON.stringify(kwargs))

    try {
      await fetchCompletion('/api/ai/writer', formData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading({ name: loaderName || 'generate', status: false })
    }
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  //save input values into history
  useEffect(() => {
    dispatchAction(
      setCheckTextInputHistory({
        myText,
        model,
        creativity,
        isGuruModeEnabled,
        selectOptionValues: state,
      })
    )
    dispatchAction(setCheckTextGeneratedResultHistory(generatedText))
  }, [myText, creativity, loading, state, isGuruModeEnabled, model])

  return (
    <>
      <ToolLayoutPrimary
        folded={folded}
        setFolded={setFolded}
        storageKey='check-text'
        leftSideContent={
          <CheckTextGenerator
            dispatch={dispatch}
            actionTypes={actionTypes}
            setWordLength={setWordLength}
            setMyText={setMyText}
            wordLength={wordLength}
            setWriterLengthOption={setWriterLengthOption}
            writerLengthOption={writerLengthOption}
            setCreativity={setCreativity}
            creativity={creativity}
            handleSubmitWriterOptions={handleSubmitWriterOptions}
            setModalIsVisible={setModalIsVisible}
            clearInputs={clearInputs}
            optionsData={optionsData}
            // templateOptions={templateOptions}
            setTemplateId={setTemplateId}
            loading={loading}
            history={history}
            setHistory={setHistory}
            checkedHistory={checkedHistory}
            setCheckedHistory={setCheckedHistory}
            reloadHistoryData={reloadHistoryData}
            isGuruModeEnabled={isGuruModeEnabled}
            setIsGuruModeEnabled={setIsGuruModeEnabled}
            // handleSetValuesToInputs={handleSetValuesToInputs}
            model={model}
            setModel={setModel}
          />
        }
        rightSideContent={
          <WriterResult
            generatedText={generatedText}
            setGeneratedText={setGeneratedText}
            regenerate={() => handleSubmitWriterOptions('regenerate')}
            folded={folded}
            loading={loading}
            toolName='writer-checkText'
            handleSaveGeneratedTextHistory={(text) =>
              dispatchAction(setCheckTextGeneratedResultHistory(text))
            }
            handleStopRequest={handleStopRequest}
            selectedLanguage={state.language}
            showLength={false}
            topic={myText}
            key={timeStamp}
          />
        }
      >
        <>
          <HelpTooltips group='writer' tool='checkText' />
          <Tour steps={steps} name='writer-check' />
        </>
      </ToolLayoutPrimary>
    </>
  )
}

export default CheckTextLayout
