import {
  CancelOutlined,
  History,
  ImageOutlined,
  KeyboardArrowRight,
  ViewAgendaOutlined,
} from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { SaveButton } from 'components/templates/SaveButton'
import { TemplatesButton } from 'components/templates/TemplatesButton'
import { Sparkle } from 'phosphor-react'
import { Microbutton } from 'ui'

export function Footer({
  dispatch,
  isLoading,
  handleSubmit,
  handleSaveTemplate,
  handleUpdateTemplate,
  lastUsedTemplate,
  templates,
  isLayoutLarge,
  setIsDisplayOutput,
  history,
  handleDeleteTemplate,
  setTemplate,
  documentContext,
  isReady,
}) {
  const { t } = useTranslate()

  if (isLayoutLarge)
    return (
      <div className='bg-brand-grey-bg border-brand-gray-light -mr-2 flex h-[72px] gap-[10px] border-t p-[16px]'>
        <div className='flex'>
          <SaveButton
            handleSaveTemplate={handleSaveTemplate}
            handleUpdateTemplate={handleUpdateTemplate}
            lastUsedTemplate={lastUsedTemplate}
            templates={templates}
          />

          <Microbutton
            color='gray'
            textClasses='!text-[14px]'
            className='hover:bg-brand-form-accent text-brand-violet-light bg-brand-form-accent-light hover:text-brand-violet h-[40px] rounded-l-none uppercase'
            variant='plain'
            type='main'
            onClick={() => {
              documentContext.clearContext()
              dispatch({
                type: 'RESET',
              })
            }}
            data-tooltip-id='images-imageCreator-clear'
            text={t('eleo-history-action-clear', 'clear')}
          />
        </div>
        <Microbutton
          onClick={handleSubmit}
          type='main'
          color='violet'
          className={classNames(
            'h-[40px] flex-1 !rounded-[4px] text-[15px] font-medium uppercase leading-[1.2em]',
            (isLoading || !isReady) && 'opacity-80'
          )}
          disabled={isLoading || !isReady}
          data-tooltip-id='images-imageCreator-generateImage'
        >
          <div className='flex items-center gap-[10px]'>
            <div className='text-nowrap'>{t('eleo-tool-create-image-generate-action')}</div>
            <Sparkle fontSize={18} weight='fill' />
          </div>
        </Microbutton>
      </div>
    )

  return (
    <div className='border-brand-gray-light flex gap-[8px] border-t p-2'>
      <div className='flex gap-[2px]'>
        {/* <SaveButton
          handleSaveTemplate={handleSaveTemplate}
          handleUpdateTemplate={handleUpdateTemplate}
          lastUsedTemplate={lastUsedTemplate}
          templates={templates}
        /> */}

        <Microbutton
          icon={<CancelOutlined fontSize='inherit' />}
          className='hover:!bg-brand-form-accent-light hover:!text-brand-violet active:!bg-brand-violet size-[40px] active:!text-white'
          variant='transparent'
          onClick={() =>
            dispatch({
              type: 'RESET',
            })
          }
        />
        {/* <Microbutton
          icon={<History fontSize='inherit' />}
          className='size-[40px]'
          variant='transparent'
          onClick={() =>
            dispatch({
              type: 'RESET',
            })
          }
        /> */}

        <TemplatesButton
          templates={templates}
          isLayoutLarge={false}
          lastUsedTemplate={lastUsedTemplate}
          handleSaveTemplate={handleSaveTemplate}
          handleUpdateTemplate={handleUpdateTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
          setTemplate={setTemplate}
        />
      </div>

      <Microbutton
        onClick={() => {
          const submitOk = handleSubmit()
          if (submitOk) setIsDisplayOutput(true)
        }}
        type='main'
        className={classNames(
          'h-[40px] flex-1 !rounded-[4px] text-[15px] font-medium uppercase leading-[1.2em]',
          (isLoading || !isReady) && 'opacity-80'
        )}
        disabled={isLoading || !isReady}
      >
        <div className='flex items-center gap-[8px]'>
          <div>{t('eleo-tool-create-image-generate-short', 'generate')}</div>
          <Sparkle fontSize={18} weight='fill' />
        </div>
      </Microbutton>

      {Boolean(history.length) && (
        <Microbutton
          className='h-[40px] w-[54px] !p-0'
          variant='transparent'
          onClick={() => setIsDisplayOutput(true)}
        >
          <div className='text-nowrap pl-1 text-[18px]'>
            <ImageOutlined fontSize='inherit' />
            <KeyboardArrowRight fontSize='inherit' />
          </div>
        </Microbutton>
      )}
    </div>
  )
}
