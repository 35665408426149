import { useTranslate } from '@tolgee/react'

export const useChatbotCategories = () => {
  const { t } = useTranslate()

  const chatbotCategories = [
    {
      value: 'eleo-chatbot-category-experts',
      label: t('eleo-chatbot-category-experts', 'Experts'),
    },
    {
      value: 'eleo-chatbot-category-education',
      label: t('eleo-chatbot-category-education', 'Education'),
    },
    {
      value: 'eleo-chatbot-category-entertainment',
      label: t('eleo-chatbot-category-entertainment', 'Entertainment'),
    },
    {
      value: 'eleo-chatbot-category-other',
      label: t('eleo-chatbot-category-other', 'Other'),
    },
  ]

  return { chatbotCategories }
}
