import { createRoot } from 'react-dom/client'
import App from './app/app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

serviceWorkerRegistration.register()
// serviceWorkerRegistration.unregister()
