import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { T } from '@tolgee/react'
import { Link } from 'react-router-dom'
import { Input } from 'ui'

// const tabs = [
//     { name: 'Open', href: '#', current: true },
//     { name: 'Closed', href: '#', current: false },
//   ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CategoriesSwitch({ filter, setFilter }) {
  const { chatbotCategories } = useChatbotCategories()
  return (
    <div className='mb-5 border-b border-gray-200'>
      <div className='justify-between sm:flex sm:items-baseline'>
        <div className='justify-between sm:flex sm:items-baseline'>
          <h3 className='text-base font-semibold leading-6 text-gray-900'>
            <Link to='/chatbot/library/'>
              <T keyName='eleo-chatbot-library-categories-title'>Categories</T>
            </Link>
          </h3>
          <div className='mt-4 sm:ml-10 sm:mt-0'>
            <nav className='-mb-px flex space-x-8'>
              {chatbotCategories.map((chatbotCategory) => (
                <Link
                  key={chatbotCategory.value}
                  to={`/chatbot/library/category/${chatbotCategory.value}`}
                  aria-current={chatbotCategory.current ? 'page' : undefined}
                  className={classNames(
                    chatbotCategory.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                >
                  {chatbotCategory.label}
                </Link>
              ))}
            </nav>
          </div>
        </div>

        {setFilter && <Input value={filter} onChange={setFilter} />}
      </div>
    </div>
  )
}
