const links = {
  imageEditing: {
    name: 'image-editing',
    text: 'More about working with generated images',
    links: {
      pl: 'https://docs.eleo.ai/v/pl/praca-z-wygenerowanymi-tresciami/praca-z-obrazami',
      en: 'https://docs.eleo.ai/working-with-generated-content/working-with-images',
    },
  },
  textEditing: {
    name: 'text-editing',
    text: 'More about working with generated text',
    links: {
      pl: 'https://docs.eleo.ai/v/pl/praca-z-wygenerowanymi-tresciami/praca-z-tekstem',
      en: 'https://docs.eleo.ai/working-with-generated-content/working-with-text',
    },
  },
  templates: {
    name: 'templates',
    text: 'More about working with templates',
    links: {
      pl: 'https://docs.eleo.ai/v/pl/inne/szablony-uzytkownika',
      en: 'https://docs.eleo.ai/other/user-templates',
    },
  },
}

export default links
