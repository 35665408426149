import { T } from '@tolgee/react'
import axios from 'axios'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { useWindowDimensions } from 'components/hooks/window'
import { Animate, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectClientId,
  selectFinished,
  selectPending,
  selectQueue,
  setFinished,
  setPending,
  setQueue,
} from 'store/generations'
import {
  selectImageCreatorInputHistory,
  selectImageHolderHistoryValues,
  setImageHolderHistoryValues,
} from 'store/toolInputHistoriesHolder/image-creator'
import ImageForm from 'toolComponents/image/create/ImageForm'
import { resolutions } from 'toolComponents/image/models/imageSizes'
import { Footer } from 'toolComponents/image/output/Footer'
import ImagesOutput from 'toolComponents/image/output/ImageOutput'
import { ResizableContainer } from 'ui'
import { v4 as uuidv4 } from 'uuid'

// Tour steps
const steps = [
  {
    target: '#description',
    content: (
      <T keyName='eleo-tour-image-1'>
        Describe your image. Write what should be in the foreground, background, what colors, light,
        shadows, and mood the image should have.
      </T>
    ),
  },
  {
    target: '#samples',
    content: (
      <T keyName='eleo-tour-image-2'>
        Choose the number of generated images. The more you choose, the greater the selection, but
        you will have to wait longer.
      </T>
    ),
  },
  {
    target: '#style',
    content: (
      <T keyName='eleo-tour-image-3'>
        Choose a style. Your image can be realistic or mimic a chosen artistic style.
      </T>
    ),
  },
  {
    target: '#save-template',
    content: (
      <T keyName='eleo-tour-image-4'>
        Save your settings as a template. Use the template to generate multiple images in a similar
        style.
      </T>
    ),
  },
  {
    target: '#image-navigation',
    content: (
      <T keyName='eleo-tour-image-5'>
        Navigate between versions using arrows. You can go back to previously generated images.
      </T>
    ),
    placementBeacon: 'top',
  },
  {
    target: '#context-menu',
    content: (
      <T keyName='eleo-tour-image-6'>
        Use the context menu. Hover over the selected image to zoom in, modify, save to the gallery,
        or download to your disk.
      </T>
    ),
  },
]

export function Image() {
  const context = useContext(ViewContext)
  const pendingGeneration = useSelector(selectPending).generate
  const finishedGeneration = useSelector(selectFinished).generate
  const clientId = useSelector(selectClientId)
  const queueSize = useSelector(selectQueue).generate
  const { width } = useWindowDimensions()
  const documentContext = useDocumentContext('image')

  const imageHolderHistoryValues = useSelector(selectImageHolderHistoryValues)
  const dispatchAction = useDispatch()

  // Sync these with redux to enable off-screen generation (additional logic required)
  const [isLoading, setIsLoading] = useState(imageHolderHistoryValues.isLoading)

  const [history, setHistory] = useState(imageHolderHistoryValues.history)
  const [currentIndex, setCurrentIndex] = useState(imageHolderHistoryValues.currentIndex)
  const [selectedImage, setSelectedImage] = useState(imageHolderHistoryValues.selectedImage)
  const [imagePreviewData, setImagePreviewData] = useState({
    target: '',
    isMobile: false,
    isVisible: false,
  })
  const [isFormOpen, setIsFormOpen] = useState(imageHolderHistoryValues.isOpen)
  const [previewData, setPreviewData] = useState({ proportions: '1:1', amount: 2 })

  // Only used for mobile layout
  const [isDisplayOutput, setIsDisplayOutput] = useState(false)

  async function requestGeneration(formState) {
    try {
      const data = {
        clientId: clientId,
        data: { action: 'generate', ...formState },
        context: documentContext.docContext?.filter((item) => !item.invalid),
        promptId: uuidv4(),
      }

      // Save form data to associate with generation later
      dispatchAction(
        setPending({
          generate: { id: data.promptId, data: formState, inQueue: true },
        })
      )
      if (!history.length || history[history.length - 1]?.images.length)
        setHistory((prev) => [...prev, { images: [], data: formState }])

      const res = await axios.post('/api/ai/comfy', data)
      if (res.data.prompt_id !== data.promptId) throw new Error('Image generation failed')

      context.setImagesLeft((prev) => prev - formState.samples)

      return res.data
    } catch (err) {
      setIsLoading(false)
      dispatchAction(
        setPending({
          generate: {},
        })
      )

      context.handleError(err)
    }
  }

  const getGeneration = useCallback(
    async (promptId) => {
      // if (promptId !== pendingGeneration.promptId) return
      try {
        const res = await axios.get(`/api/ai/comfy/${promptId}`)

        let images = []
        if (finishedGeneration.data.moreLikeThis)
          images = [finishedGeneration.data.image, ...res.data]
        else images = res.data

        handleImageReady(images, finishedGeneration.data)
      } catch (err) {
        context.handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [context, finishedGeneration.data]
  )

  async function abortGeneration() {
    try {
      const data = { promptId: pendingGeneration.id, samples: pendingGeneration.data?.samples }
      await axios.post(`/api/ai/comfy/abort`, data)

      dispatchAction(setQueue({ generate: 0 }))
      dispatchAction(
        setPending({
          generate: {},
        })
      )
      setHistory((prev) => [...prev.slice(0, -1), { images: [], data: null }])

      context.setImagesLeft((prev) => prev + Number(pendingGeneration.data?.samples))

      setIsLoading(false)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!finishedGeneration?.id) return

    getGeneration(finishedGeneration.id)
    dispatchAction(
      setFinished({
        generate: {},
      })
    )
  }, [finishedGeneration, dispatchAction, getGeneration])

  useLayoutEffect(() => {
    setCurrentIndex(history.length - 1)
  }, [history])

  // Update display proportions
  const { chosenProportions, samples } = useSelector(
    selectImageCreatorInputHistory,
    (a, b) => a.chosenProportions === b.chosenProportions && a.samples === b.samples // rerender only when chosenProportions or samples change
  )
  useLayoutEffect(() => {
    const options = Object.entries(resolutions)
      .map((item) => item[1])
      .flat()

    const imageProportions = history[currentIndex]?.data?.chosenProportions ?? chosenProportions

    let newSamples = samples
    if (history[currentIndex]?.data)
      newSamples = history[currentIndex]?.data?.moreLikeThis
        ? history[currentIndex]?.data?.samples + 1
        : history[currentIndex]?.data?.samples

    setPreviewData({
      proportions: options.find((item) => item.value === imageProportions)?.proportions,
      samples: newSamples,
    })
  }, [chosenProportions, samples, history, currentIndex, setPreviewData])

  function handleImageReady(images, imageData) {
    setHistory((prev) => [...prev.slice(0, -1), { images: images, data: imageData }])
  }

  useEffect(() => {
    dispatchAction(
      setImageHolderHistoryValues({
        history,
        selectedImage,
        currentIndex,
        isLoading,
      })
    )
  }, [history, selectedImage, currentIndex, isLoading, dispatchAction])

  const isLayoutLarge = width >= 1024

  const FormComponent = (
    <ImageForm
      handleImageReady={handleImageReady}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      requestGeneration={requestGeneration}
      getGeneration={getGeneration}
      queueSize={queueSize}
      isLayoutLarge={isLayoutLarge}
      setIsDisplayOutput={setIsDisplayOutput}
      history={history}
      documentContext={documentContext}
    />
  )

  const OutputComponent = (
    <div className='flex h-full flex-col'>
      <ImagesOutput
        images={history[currentIndex]?.images}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        imageData={history[currentIndex]?.data}
        imagePreviewData={imagePreviewData}
        setImagePreviewData={setImagePreviewData}
        setIsLoading={setIsLoading}
        handleImageReady={handleImageReady}
        requestGeneration={requestGeneration}
        currentIndex={currentIndex}
        isGenerating={isLoading}
        history={history}
        queueSize={queueSize}
        previewData={previewData}
        handleAbort={abortGeneration}
      />
      <Footer
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        history={history}
        isGenerating={isLoading}
        setIsDisplayOutput={setIsDisplayOutput}
        isLayoutLarge={isLayoutLarge}
      />
    </div>
  )

  return (
    <>
      <Animate type='pop'>
        {isLayoutLarge ? (
          <div className='flex h-full'>
            {/* Input Data */}
            <ResizableContainer
              isOpen={isFormOpen}
              setIsOpen={setIsFormOpen}
              storageKey='images'
              minWidth={400}
              initWidth={500}
              maxWidth={Math.min(900, (width - 200) / 2)}
            >
              {FormComponent}
            </ResizableContainer>

            {/* Output Data  */}
            <div className='flex min-w-0 flex-1 flex-col'>{OutputComponent}</div>
          </div>
        ) : isDisplayOutput ? (
          OutputComponent
        ) : (
          FormComponent
        )}
      </Animate>

      <HelpTooltips group='images' tool='imageCreator' />
      <Tour steps={steps} name='images' />
    </>
  )
}
