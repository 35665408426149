import React, { useEffect, useState, useRef, useCallback } from 'react'
import Webcam from 'react-webcam'
import { v4 as uuidv4 } from 'uuid'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CachedIcon from '@mui/icons-material/Cached'
import ClearIcon from '@mui/icons-material/Clear'
import { useWindowDimensions } from 'components/hooks/window'
import { toast } from 'react-toastify'
import { useTranslate } from '@tolgee/react'

const videoConstrainOptions = {
  width: 1280,
  height: 720,
  facingMode: 'user',
}

export function WebCamComponent({ setShowWebCam, handleTakenPicUpload }) {
  const [videoConstraints, setVideoConstrains] = useState(videoConstrainOptions)
  const [showActionButtons, setShowActionButtons] = useState(false)
  const webcamRef = useRef(null)
  const { t } = useTranslate()
  const changeCameraView = () => {
    setVideoConstrains((prev) => ({
      ...prev,
      facingMode: prev.facingMode === 'user' ? { exact: 'environment' } : 'user',
    }))
  }
  const id = uuidv4()
  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    const fileName = `Picture-${id}`
    //convert base64 into file
    const res = await fetch(imageSrc)
    const blob = await res.blob()
    const file = new File([blob], fileName, { type: 'image/jpeg' })
    handleTakenPicUpload(file)
  }, [webcamRef])

  useEffect(() => {
    async function checkCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        //permission was granted
        stream.getTracks().forEach((track) => track.stop())
      } catch (error) {
        setShowWebCam(false)
        toast.error(t('eleo-camera-not-allowed', "You don't have camera permissions enabled!"))
      }
    }

    checkCameraPermission()
  }, [])

  return (
    <div className='fixed left-0 right-0 top-0 z-[999999999999]'>
      <Webcam
        audio={false}
        ref={webcamRef}
        mirrored={false}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        className='fixed h-full w-full object-cover'
        onUserMedia={() => {
          setShowActionButtons(true)
        }}
      />
      {showActionButtons ? (
        <div className='fixed bottom-[20px] left-1/2 z-[999999]  flex h-[40] -translate-x-1/2 items-center gap-[30px]'>
          <button
            onClick={capture}
            className='bg-brand-violet flex h-[50px] w-[50px] items-center justify-center rounded-full shadow-md'
          >
            <CameraAltIcon className='size-10  text-white' />
          </button>
          <button
            onClick={changeCameraView}
            className='bg-brand-violet block flex  h-[50px] w-[50px] items-center justify-center rounded-full shadow-md lg:hidden'
          >
            <CachedIcon className='size-10  text-white' />
          </button>
          <button
            onClick={() => setShowWebCam(false)}
            className='bg-brand-pink flex h-[50px] w-[50px] items-center justify-center rounded-full shadow-md'
          >
            <ClearIcon className='size-10  text-white' />
          </button>
        </div>
      ) : null}
    </div>
  )
}
