/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { T, useTranslate, useTolgee } from '@tolgee/react'
import { CursorModeContext } from 'app/cursorMode'
import { LangSelector } from 'components/LangSwitcher'
import { useWindowDimensions } from 'components/hooks/window'
import { AuthContext, ViewContext } from 'components/lib'
import { Fragment, useContext, useState } from 'react'
import { AppNav, Header } from 'ui'
import { CONSENT_COOKIE_EXPIRATION, getLandingUrl, getParentDomain } from '../../../helpers'
import eleoLogo from '../../../images/sygnet.svg'
import { getAccountNavData, AppNavData, getBottomItem } from './data/nav'
import { helpMenuData } from './data/header'
import Cookies from 'js-cookie'
import { getDocsUrl } from 'helpers'

export function AppLayout(props) {
  const context = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const helpContext = useContext(CursorModeContext)
  const tolgee = useTolgee(['pendingLanguage'])

  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const planName = authContext.user?.plan?.name
  const isOwner = ['owner', 'master'].includes(authContext.user?.permission)
  const email = authContext.user?.email

  const AccountNavData = getAccountNavData({ isOwner, planName, signOut: authContext.signout })
  const docsUrl = getDocsUrl(tolgee.getPendingLanguage())

  return (
    <Fragment>
      <AppNav
        logo={eleoLogo}
        items={AppNavData}
        accountItems={AccountNavData}
        bottomItem={{ ...getBottomItem(), link: docsUrl }}
        open={mobileMenuOpen}
        avatar={context.avatar}
        words={context.words}
        images={context.images}
        tokens={props.tokens ?? 'words'}
        email={email}
        plan={authContext.user?.plan?.name}
        isOwner={isOwner}
        setMobileMenuOpen={setMobileMenuOpen}
        upgradeButtonText={<T keyName='eleo-appnav-upgrade-button'>Upgrade</T>}
        langSelector={<LangSelector className={'!w-[140px]'} />}
        translations={{
          plan: t('eleo-profile-plan', 'plan'),
          left: t('eleo-profile-tokens-left', 'left'),
          docs: t('eleo-help-docs', 'User guide'),
        }}
        docsUrl={docsUrl}
      />

      <Header
        logo={eleoLogo}
        logoUrl={`${getLandingUrl()}?bypass=true`}
        docsUrl={docsUrl}
        words={context.words}
        images={context.images}
        tokens={props.tokens ?? 'words'}
        email={email}
        plan={planName}
        avatar={context.avatar}
        isOwner={isOwner}
        setMobileMenuOpen={setMobileMenuOpen}
        upgradeButtonText={<T keyName='eleo-appnav-upgrade-button'>Upgrade</T>}
        langSelector={<LangSelector />}
        translations={{
          plan: t('eleo-profile-plan', 'plan'),
          left: t('eleo-profile-tokens-left', 'left'),
        }}
        cursorModeContext={helpContext}
        helpTranslations={{
          title: <T keyName='eleo-help-tooltips'>Help tooltips</T>,
          descriptionMobile: (
            <T keyName='eleo-help-description-mobile'>
              Tap here to enable/disable help mode, where you tap on items to see their description.
            </T>
          ),
          descriptionDesktop: (
            <T keyName='eleo-help-description-desktop'>
              Click here to enable help mode and hover over an item to see its description. Click
              again to turn off help mode.
            </T>
          ),
          helpMode: <T keyName='eleo-help-mode'>Help Mode</T>,
          helpModeLabel: (
            <T keyName='eleo-help-mode-label'> Show help tooltips on crucial elements</T>
          ),
          startTour: <T keyName='eleo-help-start-tour'>Start Tour</T>,
          docs: <T keyName='eleo-help-docs'>Help docs</T>,
        }}
        isLayoutLarge={width >= 1024}
        helpMenuData={helpMenuData}
      />

      <main className='lg:pl-sidebar h-full pt-[3.75rem]'>
        {<props.children {...props.data} />}
      </main>
    </Fragment>
  )
}
