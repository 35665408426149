import { useAccess } from 'components/hooks/access'
import { ToolGeneratorLayoutPrimary, ViewContext } from 'components/lib'
import { useContext } from 'react'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import WriterHeader from 'toolComponents/writer/WriterHeader'
import AdvisorActions from './AdvisorActions'
import AdvisorOptions from './AdvisorOptions'

const AdvisorGenerator = ({
  loading,
  dispatch,
  actionTypes,
  setWordLength,
  templateOptions,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  optionsData,
  setKeyWords,
  setTopic,
  setAdvisorContext,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  includeSources,
  setIncludeSources,
  submitAdvisor,
  handleClear,
  setModalIsVisible,
  setTemplateId,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  contextPlaceHolder,
  topicPlaceHolder,
  handleClearTemplate,
  isHistoryVisible,
  setIsHistoryVisible,
  documentContext,
  model,
  setModel,
}) => {
  const context = useContext(ViewContext)
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const showUpgradePlanModal = () => {
    context.modal.show({
      children: <UpgradePlan />,
      modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
    })
  }

  const showSaveModal = () => {
    if (allowStandard) {
      setModalIsVisible({ content: 'SAVE_TEMPLATE', visible: true })
    } else {
      showUpgradePlanModal()
    }
  }

  return (
    <>
      <ToolGeneratorLayoutPrimary
        topContent={
          <WriterHeader
            historyIsVisible={isHistoryVisible}
            setHistoryIsVisible={setIsHistoryVisible}
            headerTitle='advisor'
            toolName='advisor'
          />
        }
        options={
          <AdvisorOptions
            dispatch={dispatch}
            actionTypes={actionTypes}
            setWordLength={setWordLength}
            templateOptions={templateOptions}
            wordLength={wordLength}
            setWriterLengthOption={setWriterLengthOption}
            writerLengthOption={writerLengthOption}
            optionsData={optionsData}
            setKeyWords={setKeyWords}
            setTopic={setTopic}
            setAdvisorContext={setAdvisorContext}
            isGuruModeEnabled={isGuruModeEnabled}
            setIsGuruModeEnabled={setIsGuruModeEnabled}
            includeSources={includeSources}
            setIncludeSources={setIncludeSources}
            visible={!isHistoryVisible}
            allowStandard={allowStandard}
            showUpgradePlanModal={showUpgradePlanModal}
            setTemplateId={setTemplateId}
            templateId={templateId}
            handleDeleteTemplate={handleDeleteTemplate}
            isShowAdvanced={isShowAdvanced}
            setIsShowAdvanced={setIsShowAdvanced}
            contextPlaceHolder={contextPlaceHolder}
            topicPlaceHolder={topicPlaceHolder}
            handleClearTemplate={handleClearTemplate}
            documentContext={documentContext}
            model={model}
            setModel={setModel}
          />
        }
        actions={
          <AdvisorActions
            loading={loading}
            clearInputs={handleClear}
            handleSubmitWriterOptions={submitAdvisor}
            showSaveModal={showSaveModal}
            allowStandard={allowStandard}
          />
        }
      ></ToolGeneratorLayoutPrimary>
    </>
  )
}

export default AdvisorGenerator
