import { Animate } from 'components/lib'
import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import { ChatbotLibraryListLayout } from 'toolComponents/chat-bot/library/LibraryListLayout'
import { useTranslate } from '@tolgee/react'

export const ChatbotLibraryLiked = () => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()

  const fetchItems = useCallback(async () => {
    try {
      const res = await axios.get(`/api/chat-bot/library/liked?page=1&pageSize=20`)
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [context])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const title = t('liked-chatbots-title', 'Your liked Chatbots')

  return (
    <Animate>
      <ChatbotLibraryListLayout items={items} title={title} fetchItems={fetchItems} />
    </Animate>
  )
}

export default ChatbotLibraryLiked
