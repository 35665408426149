import { Account } from 'views/account'
import { Profile } from 'views/account/profile'
import { Billing } from 'views/account/billing'
import { Upgrade } from 'views/account/upgrade'
import { Packs } from 'views/account/packs/packs'
import { Users } from 'views/account/users'
import { Password } from 'views/account/password'
import { TwoFA } from 'views/account/2fa'
import { APIKeyList } from 'views/account/apikey/list'
import { APIKeyEditor } from 'views/account/apikey/edit'
import Plans from 'views/account/plans/plans'
import { BillingInvoices } from 'views/account/billing/invoices'
import { BillingCard } from 'views/account/billing/card'
import YourStory from 'views/account/yourStory'
import ChatBot from 'views/account/chatBot'
import { Redeem } from 'views/account/redeem'
import { Return } from 'views/account/return'

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-your-account',
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-your-profile',
  },
  {
    path: '/account/your-story',
    view: YourStory,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-your-story',
  },
  {
    path: '/account/chat-bot',
    view: ChatBot,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-chatbot',
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-change-password',
  },
  {
    path: '/account/redeem',
    view: Redeem,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-redeem',
  },
  {
    path: '/account/2fa',
    view: TwoFA,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-2fa',
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-billing',
  },
  {
    path: '/account/packs',
    view: Packs,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-packs',
  },
  {
    path: '/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-upgrade',
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'app',
    permission: 'admin',
    title: 'eleo-title-users',
  },
  {
    path: '/account/apikeys',
    view: APIKeyList,
    layout: 'app',
    permission: 'developer',
    title: 'API Keys',
  },
  {
    path: '/account/apikeys/create',
    view: APIKeyEditor,
    layout: 'app',
    permission: 'developer',
    title: 'API Keys',
  },
  {
    path: '/account/apikeys/edit',
    view: APIKeyEditor,
    layout: 'app',
    permission: 'developer',
    title: 'API Keys',
  },
  {
    path: '/account/plans',
    view: Plans,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-plans',
  },
  {
    path: '/account/invoices',
    view: BillingInvoices,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-plans',
  },
  {
    path: '/account/card',
    view: BillingCard,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-card',
  },
  {
    path: '/account/return',
    view: Return,
    layout: 'app',
    permission: 'owner',
    title: 'eleo-title-plans',
  },
]

export default Routes
