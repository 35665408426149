import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useAccess } from 'components/hooks/access'
import { ButtonGroup, Input, ReactSelect, Slider, Toggle } from 'ui'
import ImageDropZone from '../ImageDropZone'
import ToolSelector from '../ToolSelector'
import { styles } from '../models/imageOptions'

export default function ChangeBackgroundLayout({
  state,
  dispatch,
  baseImage,
  imageData,
  handleFileUpload,
  showUpgradePlanModal,
  templateSelection,
  showDeleteConfirmationModal,
  setBaseImage,
}) {
  const { t } = useTranslate()
  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const isModify = state.isBgModify

  return (
    <>
      {/* Image Selector */}
      <ImageDropZone
        baseImage={baseImage}
        handleFileUpload={handleFileUpload}
        setBaseImage={setBaseImage}
      />

      <ToolSelector activeTool={state.tool} dispatch={dispatch} />

      <form className='flex flex-1 flex-col justify-between text-[13px]'>
        {/* Form contents */}
        <div
          className='grid gap-x-3 gap-y-4 px-4 py-5 2xl:gap-x-4'
          style={{
            gridTemplateColumns: 'auto minmax(0, 1fr) auto minmax(0, 1fr)',
          }}
        >
          <div className='col-span-4 flex items-center gap-4 pt-2'>
            <div className='font-medium'>
              <T keyName='eleo-image-modify-bg-remove-bg'>Remove background</T>
            </div>
            <Toggle
              isChecked={state.isBgModify}
              setIsChecked={(val) =>
                dispatch({
                  type: 'SET_IS_BG_MODIFY',
                  payload: val,
                })
              }
            />
            <div className='font-medium'>
              <T keyName='eleo-image-modify-bg-modify-bg'>Modify background</T>
            </div>
          </div>

          <label className={classNames('my-auto font-medium')}>
            <T keyName='eleo-write-text-template'>Template</T>
          </label>
          <div className={classNames('col-span-3 my-auto text-[16px] sm:col-span-2')}>
            <ReactSelect
              options={templateSelection}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={templateSelection.find((item) => item.value === state.template) || ''}
              setSelectedValue={(val) => {
                if (!allowStandard) return showUpgradePlanModal()

                dispatch({
                  type: 'SET_TEMPLATE',
                  payload: val.value,
                })
              }}
              showDeleteIcon
              deleteAction={(templateId) => showDeleteConfirmationModal(templateId)}
            />
          </div>
          <div className='col-span-1 hidden sm:block'></div>

          <div className={classNames('pt-2 font-medium', !isModify && 'opacity-70')}>
            <T keyName='eleo-write-text-keywords'>Keywords</T>
          </div>
          <div
            className='col-span-3 lg:col-span-1'
            data-tooltip-id='images-modifyImage-description'
          >
            <Input
              className='w-full'
              disabled={!isModify}
              placeholder={
                imageData
                  ? t(
                      'eleo-image-modify-description-placeholder-1',
                      'Describe what you want to see.'
                    )
                  : t('eleo-image-modify-description-placeholder-2', 'Describe your image')
              }
              value={state.prompt}
              onChange={(val) =>
                dispatch({
                  type: 'SET_PROMPT',
                  payload: val,
                })
              }
            />
          </div>
          <label className={classNames('my-auto font-medium', !isModify && 'opacity-70')}>
            <T keyName='eleo-tool-create-image-avoid'>Avoid</T>
          </label>
          <div className='col-span-3 lg:col-span-1' data-tooltip-id='images-modifyImage-avoid'>
            <Input
              className='w-full'
              disabled={!isModify}
              placeholder={t(
                'eleo-modify-image-avoid-placeholder',
                'What would you like to avoid?'
              )}
              onChange={(val) =>
                dispatch({
                  type: 'SET_NEGATIVE_PROMPT',
                  payload: val,
                })
              }
              value={state.negativePrompt}
            />
          </div>

          <div
            className={classNames('my-auto overflow-hidden font-medium', !isModify && 'opacity-70')}
          >
            <T keyName='eleo-write-text-style'>Style</T>
          </div>
          <div
            className={classNames('col-span-3 my-auto sm:col-span-1', !isModify && 'opacity-80')}
            data-tooltip-id='images-modifyImage-style'
          >
            <ReactSelect
              options={styles}
              disabled={!isModify}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={styles.find((style) => style.value === state.style) || null}
              setSelectedValue={(val) =>
                dispatch({
                  type: 'SET_STYLE',
                  payload: val.value,
                })
              }
              menuPlacement='top'
            />
          </div>

          <div
            className={classNames('my-auto overflow-hidden font-medium', !isModify && 'opacity-70')}
          >
            <T keyName='eleo-tool-create-image-amount'>Amount</T>
          </div>
          <div
            className={classNames('col-span-3 sm:col-span-1', !isModify && 'opacity-90')}
            data-tooltip-id='images-modifyImage-amount'
          >
            <ButtonGroup
              className='w-full'
              options={[1, 2, 3, 4].map((item) => ({
                value: item,
                label: item,
              }))}
              buttonClass='overflow-x-hidden'
              value={isModify ? state.samples : 1}
              disabled={!isModify}
              onClick={(event) =>
                dispatch({
                  type: 'SET_SAMPLES',
                  payload: event.target.value,
                })
              }
            />
          </div>

          <div
            className='col-span-4 pr-3 lg:col-span-2'
            data-tooltip-id='images-modifyImage-quality'
          >
            <div className='my-auto font-medium'>
              <T keyName='eleo-tool-create-image-quality'>Quality</T>
            </div>
            <Slider
              value={state.quality}
              onChange={(val) =>
                dispatch({
                  type: 'SET_QUALITY',
                  payload: val,
                })
              }
            />

            <div className='text-brand-secondary flex justify-between text-sm'>
              <div>
                <T keyName='eleo-tool-create-image-quality-poor'>Poor</T>
              </div>
              <div>
                <T keyName='eleo-tool-create-image-quality-high'>High</T>
              </div>
            </div>
          </div>

          <div className='col-span-4 lg:col-span-2' data-tooltip-id='images-modifyImage-creativity'>
            <div className='my-auto font-medium'>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </div>
            <Slider
              value={state.creativity}
              onChange={(val) =>
                dispatch({
                  type: 'SET_CREATIVITY',
                  payload: val,
                })
              }
            />

            <div className='text-brand-secondary flex justify-between text-sm'>
              <div>
                <T keyName='eleo-tool-great-ideas-creativity-literal'>Literal</T>
              </div>
              <div>
                <T keyName='eleo-tool-great-ideas-creativity-creative'>Creative</T>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
