import { ToolGeneratorLayoutPrimary } from 'components/lib'
import WriterHeader from 'toolComponents/writer/WriterHeader'
import GreatIdeasActions from './GreatIdeasActions'
import GreatIdeasOptions from './GreatIdeasOptions'

const IdeaGenerator = ({
  dispatch,
  setText,
  setDetails,
  setNegativeWords,
  actionTypes,
  setWordLength,
  setWriterLengthOption,
  wordLength,
  writerLengthOption,
  handleGenerateIdea,
  loading,
  handleClearValues,
  creativity,
  setCreativity,
  optionsData,
  isShowAdvanced,
  setIsShowAdvanced,
  setIsGuruModeEnabled,
  isGuruModeEnabled,
  isHistoryVisible,
  setIsHistoryVisible,
  documentContext,
  model,
  setModel,
}) => {
  return (
    <>
      <ToolGeneratorLayoutPrimary
        topContent={
          <WriterHeader
            historyIsVisible={isHistoryVisible}
            setHistoryIsVisible={setIsHistoryVisible}
            showHistoryIcon={true}
            toolName='greatIdeas'
            headerTitle='GREAT IDEAS'
          />
        }
        options={
          !isHistoryVisible && (
            <GreatIdeasOptions
              dispatch={dispatch}
              setText={setText}
              setDetails={setDetails}
              setNegativeWords={setNegativeWords}
              actionTypes={actionTypes}
              setWordLength={setWordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              wordLength={wordLength}
              creativity={creativity}
              setCreativity={setCreativity}
              optionsData={optionsData}
              isShowAdvanced={isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
              visible
              documentContext={documentContext}
              model={model}
              setModel={setModel}
            />
          )
        }
        actions={
          <GreatIdeasActions
            clearInputs={handleClearValues}
            loading={loading}
            handleSubmitWriterOptions={handleGenerateIdea}
          />
        }
      />
    </>
  )
}

export default IdeaGenerator
