import { Close, History, VolumeOff, VolumeUp, Search, SearchOff } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import React from 'react'
import { LanguageSelector, Microbutton } from 'ui'

const ChatBotOptionBar = ({
  language,
  languages,
  setLanguage,
  handleClose,
  isAutoAudio,
  audioStream,
  handleVolumeOff,
  setIsAutoAudio,
  userCanAccessHistory,
  setHistoryIsVisible,
  handleClearChat,
  isSearchWebAllowed,
  isSearchWeb,
  setSearchWeb,
}) => {
  const { t } = useTranslate()
  return (
    <div className='mx-auto flex w-full max-w-[1000px] items-center justify-between gap-[5px] md:gap-0'>
      <div className='flex items-center gap-[7px]'>
        <div>
          <LanguageSelector
            label={t('eleo-chat-settings-language', 'Language')}
            language={{ label: language, value: language }}
            languages={languages}
            microButtonVariant='grey'
            onClick={(val) => setLanguage(val.value)}
            translations={{
              showAll: t('eleo-chat-settings-language-show-all', 'Show all'),
              languageNotFound: t('eleo-chat-settings-language-not-found', 'Language not found'),
              searchPlaceholder: t(
                'eleo-chat-settings-language-search-placeholder',
                'Find a language'
              ),
            }}
            callback={handleClose}
            buttonTextClassOverrides='!font-black'
            labelWrapperClassOverrides='!md:w-[85px] !w-[60px]'
          />
        </div>
        <div id='audio-switch' className='flex rounded-[4px] bg-[#000000]/5'>
          <Microbutton
            iconPlacement='left'
            variant='plain'
            icon={<VolumeOff fontSize='inherit' />}
            // disabled={isAutoAudio && !audioStream}
            onClick={handleVolumeOff}
            className={classNames(
              'rounded-r-none p-[5px]',
              isAutoAudio && !audioStream
                ? 'text-brand-body/50 hover:text-brand-body'
                : 'text-brand-body bg-brand-body/10 '
            )}
          />
          <Microbutton
            iconPlacement='left'
            variant={isAutoAudio ? 'grey' : 'plain'}
            icon={<VolumeUp fontSize='inherit' />}
            disabled={isAutoAudio}
            onClick={() => setIsAutoAudio(true)}
            className={classNames(
              'rounded-l-none p-[5px]',
              isAutoAudio && 'text-brand-body hover:bg-brand-body/70 hover:text-white',
              !isAutoAudio && 'text-brand-body/50 hover:text-brand-body'
            )}
          />
        </div>
      </div>
      <div className='flex items-center gap-[7px]'>
        {userCanAccessHistory ? (
          <div>
            <Microbutton
              title={t('eleo-tool-chat-history-header', 'History')}
              iconPlacement='left'
              text={
                <span className='hidden lg:block'>
                  {t('eleo-tool-chat-history-header', 'History')}
                </span>
              }
              variant='grey'
              icon={<History fontSize='inherit' />}
              onClick={() => setHistoryIsVisible((prev) => !prev)}
              className='h-[28px] md:h-[30px]'
              textClasses='!font-black md:px-[3px] !px-0'
            />
          </div>
        ) : null}
        {/* WWW switch */}
        {isSearchWebAllowed && (
          <div
            className='bg-brand-violet/10 flex rounded-[4px]'
            data-tooltip-id={`eleo-tooltip`}
            data-tooltip-keyname={'eleo-chat-tooltip-www'}
          >
            <Microbutton
              iconPlacement='left'
              variant='plain'
              icon={<SearchOff fontSize='inherit' />}
              disabled={!isSearchWeb}
              onClick={() => setSearchWeb(false)}
              className={classNames(
                'rounded-r-none',
                isSearchWeb
                  ? 'text-brand-violet/50 hover:text-brand-violet'
                  : 'text-brand-violet bg-brand-violet/10 '
              )}
            />
            <Microbutton
              iconPlacement='left'
              text='www'
              variant={isSearchWeb ? 'solid' : 'plain'}
              icon={<Search fontSize='inherit' />}
              disabled={isSearchWeb}
              onClick={() => setSearchWeb(true)}
              className={classNames(
                'rounded-l-none',
                !isSearchWeb && 'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
              )}
            />
          </div>
        )}
        <Microbutton
          title={t('eleo-chat-tooltip-clear-chat', 'Start a new conversation')}
          iconPlacement='left'
          text={t('eleo-chat-settings-clear-chat', 'Clear chat')}
          variant='grey'
          icon={<Close fontSize='inherit' />}
          onClick={handleClearChat}
          className='h-[30px]'
          textClasses='!font-black'
        />
      </div>
    </div>
  )
}

export default ChatBotOptionBar
