import { createSlice } from '@reduxjs/toolkit'
import aiController from 'helpers/AIController'

export const replyTextInitialState = {
  history: {
    textToReply: '',
    whatToReply: '',
    language: '',
    wordLength: { id: '500', checked: true },
    isGuruModeEnabled: false,
    writerLengthOption: 'words',
    templateId: { label: '', value: '' },
    creativity: 'Medium',
    selectOptionValues: {
      language: '',
      action: '',
    },
    showAdvanced: false,
    storyId: { label: '', value: '' },
    model: { ...aiController.getDefaultTextModel(), description: undefined },
  },
  generatedTextHistory: {
    generatedText: '',
  },
}

export const replyTextSlice = createSlice({
  name: 'replyText',
  initialState: replyTextInitialState,
  reducers: {
    setReplyTextInputHistory: (state, action) => {
      state.history = action.payload
    },
    setReplyTextGeneratedResultHistory: (state, action) => {
      state.generatedTextHistory.generatedText = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setReplyTextInputHistory, setReplyTextGeneratedResultHistory } =
  replyTextSlice.actions

//selectors
export const replyTextInputHistory = (state) => state.replyText.history
export const replyTextGeneratedResultHistory = (state) =>
  state.replyText.generatedTextHistory.generatedText

export default replyTextSlice.reducer
