import { ContentCopy } from '@mui/icons-material'
import { T } from '@tolgee/react'
import { CopyToClipBoard, processLatexDelimiters } from 'helpers'
import 'katex/dist/katex.min.css'
import { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'

function LinkRenderer(props) {
  return (
    <a href={props.href} target='_blank' rel='noreferrer'>
      {props.children}
    </a>
  )
}

const Markdown = ({ content, isSplitWords = false }) => {
  return (
    <ReactMarkdown
      className='markdown'
      rehypePlugins={[rehypeRaw, remarkGfm, rehypeKatex]}
      remarkPlugins={[remarkMath]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match && !content.startsWith('<') ? (
            <div className='group/code relative'>
              <SyntaxHighlighter
                {...props}
                children={String(children).replace(/\n$/, '')}
                style={oneLight}
                language={match[1]}
                PreTag='div'
                showLineNumbers
              />
              <div
                className='xs:block absolute -top-2 right-0 hidden cursor-pointer opacity-0 group-hover/code:opacity-100'
                onClick={() =>
                  CopyToClipBoard(
                    String(children).replace(/\n$/, ''),
                    <T keyName='eleo-code-copied-success-message'>Code copied to clipboard</T>
                  )
                }
              >
                <ContentCopy className='m-[8px]' fontSize='small' />
              </div>
            </div>
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          )
        },
        a: LinkRenderer,
        ...(isSplitWords && {
          p: (params) => renderWords(params),
          li: (params) => renderWords(params),
          h1: (params) => renderWords(params),
          h2: (params) => renderWords(params),
          h3: (params) => renderWords(params),
          h4: (params) => renderWords(params),
          h5: (params) => renderWords(params),
          div: (params) => renderWords(params),
          article: (params) => renderWords(params),
          strong: (params) => renderWords(params),
          i: (params) => renderWords(params),
        }),
      }}
    >
      {processLatexDelimiters(content)}
    </ReactMarkdown>
  )
}

const renderWords = ({ node, children, ...props }) => {
  const TagName = node.tagName === 'p' ? 'div' : node.tagName
  if (typeof children === 'object' && children.length)
    children = children.map((child) =>
      typeof child === 'string'
        ? child.split(/(\s+|\n)/).map((word, index) => {
            if (/(\s+|\n)/.test(word)) {
              return word
            } else {
              if (!word.length) return ''

              return (
                <span key={index} className='hover:bg-brand-violet/20'>
                  {word}
                </span>
              )
            }
          })
        : child
    )

  return (
    <TagName
      {...props}
      children={
        typeof children === 'string'
          ? children.split(/(\s+|\n)/).map((word, index) => {
              if (/(\s+|\n)/.test(word)) {
                return word
              } else {
                if (!word.length) return ''
                return (
                  <span key={index} className='hover:bg-brand-violet/20'>
                    {word}
                  </span>
                )
              }
            })
          : children
      }
    />
  )
}

export default memo(Markdown)
