import { Description, WebAsset } from '@mui/icons-material'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

const iconMap = {
  document: <Description fontSize='inherit' />,
  website: <WebAsset fontSize='inherit' />,
  image: <BrokenImageIcon />,
}

export function MessageContextItemBroken({ item }) {
  if (item.fileType === 'story') return null

  return (
    <div
      title={item.name}
      className='text-brand-text-secondary border-brand-pink  group relative flex h-[40px] max-w-[100px] items-center gap-[6px] rounded-[4px] border bg-black/5 p-2 pr-3 text-[12px]'
    >
      <div className='text-brand-pink flex text-[24px]'>{iconMap[item.fileType]}</div>

      <div className='truncate'>
        <div className='text-brand-pink min-w-0 max-w-[130px] truncate'>{item.name}</div>
      </div>
    </div>
  )
}
